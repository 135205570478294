import { generateDelhiStampPaper } from './DL'
import { generateAndamanAndNicobarStampPaper } from './ANDAMANNICOBAR'
import { generateAndhraPradeshStampPaper } from './ANDHRAPRADESH'
import { generateAssamStampPaper } from './ASSAM'
import { generateBiharStampPaper } from './BIHAR'
import { generateChhattisgarhStampPaper } from './CHHATTISGARH'
import { generateKarnatakaStampPaper } from './KARNATAKA'
import { generateMeghalayaStampPaper } from './MEGHALAYA'
import { generateManipurStampPaper } from './MANIPUR'
import { generateOdishaStampPaper } from './ODISHA'
import { generatePunjabStampPaper } from './PUNJAB'
import { generateUttarakhandStampPaper } from './UTTARAKHAND'
import { generateUttarPradeshStampPaper } from './UTTAR PRADESH'
import { generateTripuraStampPaper } from './TRIPURA'
import { generateTamilNaduStampPaper } from './TAMIL NADU'
import { generateRajasthanStampPaper } from './RAJASTHAN'
import { generatePuducherryStampPaper } from './PUDUCHERRY'

// Export all functions
export {
  generateDelhiStampPaper,
  generateAndamanAndNicobarStampPaper,
  generateAndhraPradeshStampPaper,
  generateAssamStampPaper,
  generateBiharStampPaper,
  generateChhattisgarhStampPaper,
  generateKarnatakaStampPaper,
  generateMeghalayaStampPaper,
  generateManipurStampPaper,
  generateOdishaStampPaper,
  generatePunjabStampPaper,
  generateUttarakhandStampPaper,
  generateUttarPradeshStampPaper,
  generateTripuraStampPaper,
  generateTamilNaduStampPaper,
  generateRajasthanStampPaper,
  generatePuducherryStampPaper
}
