/* eslint-disable react/prop-types */
import React from 'react'
import './CommonPreviewIframe.css'
const CommonPreviewIframe = ({ objectUrl }) => {
  return (
        <div>
            <iframe src={objectUrl} title="W3Schools Free Online Web Tutorials"></iframe>
        </div>
  )
}

export default CommonPreviewIframe
