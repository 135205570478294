/* eslint-disable  */
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';
import { ToWords } from 'to-words';
import moment from 'moment';
// import { fetchImage } from 'pdf-lib/lib/utils/fetchImage'
// import watermark from '../assets/images/watermark.png'

/**
 *
 * @param {blob,string} pdfRes: blob in case of system generated
 * @param {string} path: which template function is  executed
 * @param {object} clResponse: response of the stamp paper
 * @param {string} digitalSignName: only in case of bulk document
 * @returns
 */
export const generateBulkSignDocument = async (
    pdfRes,
    path,
    digitalSignName = '',
    bulkSignPositionList = [],
    currentUserIpAddress
) => {
    const toWords = new ToWords({
        converterOptions: {
            doNotAddOnly: false
        }
    });
    // Register a Fontkit instance
    // PDFDocument.registerFontkit(Fontkit)

    let pdfArrayBuffer;
    let existingPdfBytes;

    pdfArrayBuffer = await pdfRes.arrayBuffer();
    existingPdfBytes = pdfArrayBuffer;

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Fonts
    const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    // Get the first page of the document
    const pages = pdfDoc.getPages();

    console.log('pages', pages, pdfDoc);

    if (path === 'bulkDocument') {
        for (let i = 0; i < bulkSignPositionList?.length; i++) {
            const page = pages[bulkSignPositionList[i]?.page];
            // const { width, height } = page.getSize()
            console.log('Bulk position list===>', bulkSignPositionList);
            // Add header
            page.drawText('Digitally Signed By:-', {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy,
                size: 7,
                font: timesRomanFontBold
            });
            console.log('Digital sign name', digitalSignName);
            page.drawText(`Name: ${digitalSignName}`, {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8,
                size: 7,
                font: timesRomanFontBold
            });
            console.log('Ip address', currentUserIpAddress);
            page.drawText(`Location: IP : ${currentUserIpAddress}`, {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8 * 2,
                size: 7,
                font: timesRomanFontBold
            });
            page.drawText('Reason: Digidoc Signature', {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8 * 3,
                size: 7,
                font: timesRomanFontBold
            });

            const date = moment();

            // Format the date using the desired pattern
            const formattedDate = date.format('ddd MMM DD HH:mm:ss [IST] YYYY');

            page.drawText(`Date: ${formattedDate}`, {
                x: bulkSignPositionList[i]?.posx,
                y: bulkSignPositionList[i]?.posy - 8 * 4,
                size: 7,
                font: timesRomanFontBold
            });
            // x += 120
        }
        // page.drawText(`Digitally Signed By ${digitalSignName}`, {
        //   x: width - 200,
        //   y: 50,
        //   size: 10,
        //   font: timesRomanFontBold
        // })
    }

    const pdfDOc23 = await pdfDoc.save();
    const pdfBlob = await new Blob([pdfDOc23], { type: 'application/pdf' });
    if (process.env.REACT_APP_ENV_VAR === 'dev') {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = 'generated.pdf';
        link.click();
    }
    return pdfBlob;
};
