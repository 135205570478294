import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const documentApi = createApi({
  reducerPath: 'documentApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  tagTypes: ['getAllDocuments', 'getAllBulkDocuments'],
  endpoints: (builder) => ({
    getArticleCategoryByState: builder.query({
      query: (stateId) => ({
        url: `docs/artcatg?state=${stateId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getSroBranchByCity: builder.query({
      query: (CityId) => ({
        url: `city/${CityId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getArticleTemplateByStateCat: builder.query({
      query: ({ stateId, categoryId }) => ({
        url: `docs/artempl?catg=${categoryId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getAllArticleDataByState: builder.query({
      query: (params) => ({
        url: 'docs/document',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    intializeDocumentId: builder.mutation({
      query: ({ payload }) => ({
        url: 'docs/create-doc',
        method: 'POST',
        fileUpload: true,
        isUploadDocument: true,
        payload
      }),
      invalidatesTags: ['getAllDocuments'],
      transformResponse: (response) => response?.data
    }),
    getAllDocuments: builder.query({
      query: (filter) => ({
        url: 'docs/user-docs',
        method: 'GET',
        params: filter
      }),
      providesTags: ['getAllDocuments'],
      transformResponse: (response) => response?.data
    }),
    removeDocumentById: builder.mutation({
      query: (documentId) => ({
        url: `docs/delete/doc/${documentId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['getAllDocuments'],
      transformResponse: (response) => response
    }),
    getDocumentFilterStatus: builder.query({
      query: () => ({
        url: 'docs/doc-status',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getDocumentPreviewById: builder.query({
      query: (documentId) => ({
        url: `docs/artempl/preview/${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getDocumentFieldsById: builder.query({
      query: (documentId) => ({
        url: `docs/fieldsByDocument/${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getPaymentConfigDetails: builder.query({
      query: (documentId) => ({
        url: `payment/document/details/${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    saveDocument: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `docs/fields/save/${documentId}?action=draft`,
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    saveDocumentPreview: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `docs/artempl/preview/${documentId}`,
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    getReviewPartyPriority: builder.query({
      query: ({ documentId }) => ({
        url: `docs/data/rev-cnst/${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    generateDocumentPaymentId: builder.mutation({
      query: ({ payload }) => ({
        url: 'transaction/order',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    updateDocumentPaymentStaus: builder.mutation({
      query: ({ payload }) => ({
        url: 'transaction/save',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    initateDocumentPaymentCorpBa: builder.mutation({
      query: ({ payload }) => ({
        url: 'payment/document/initiate',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    sendDocumentForReview: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `docs/consent/${documentId}`,
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllDocuments'],
      transformResponse: (response) => response
    }),
    submitDocumentReviewComment: builder.mutation({
      query: ({ documentId, payload = {} }) => ({
        url: `docs/consent/${documentId}?action=accept`,
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllDocuments'],
      transformResponse: (response) => response
    }),
    getComments: builder.query({
      query: (documentId) => ({
        url: `comments/doc/${documentId}`,
        method: 'GET'
      }),
      providesTags: ['getComments'],
      transformResponse: (response) => response?.data
    }),
    addComment: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `comments/doc/${documentId}`,
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getComments'],
      transformResponse: (response) => response?.data
    }),
    addCommentReply: builder.mutation({
      query: ({ payload, commentId }) => ({
        url: `comments/reply?comment=${commentId}`,
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getComments'],
      transformResponse: (response) => response?.data
    }),
    submitChangeRequest: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `comments/publish?document=${documentId}`,
        method: 'PATCH',
        payload
      }),
      invalidatesTags: ['getComments']
    }),
    getAllBulkDocuments: builder.query({
      query: (filter) => ({
        url: 'docs/upload/bulk/list',
        method: 'GET',
        params: filter
      }),
      providesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response?.data
    }),
    bulkDocumentUpload: builder.mutation({
      query: ({ payload, params }) => ({
        url: 'docs/upload/bulk',
        method: 'POST',
        payload,
        isUploadDocument: true,
        fileUpload: true,
        params
      }),
      transformResponse: (response) => response?.data
    }),
    getAllDocumentsByBulkId: builder.query({
      query: (bulkId) => ({
        url: `docs/bulk/view/documents?bulkid=${bulkId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getBulkDocumentSampleFile: builder.query({
      query: (params) => ({
        url: 'docs/bulk/download/sample',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    initateBulkDocumentPaymentCorp: builder.mutation({
      query: ({ payload }) => ({
        url: 'payment/document/initiate',
        method: 'POST',
        payload
      }),
      invalidatesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response
    }),
    deleteDocumentByBulkId: builder.mutation({
      query: (params) => ({
        url: 'docs/bulk/delete',
        method: 'DELETE',
        params
      }),
      invalidatesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response
    }),
    getBulkDocumentIndividualDetailsById: builder.query({
      query: (id) => ({
        url: `docs/bulk/document/view?document=${id}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getBulkDocumentIndividualFileById: builder.query({
      query: (id) => ({
        url: `docs/bulk/document/preview/view?document=${id}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    uploadDocumentHtmlTopdf: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `esign/linkPdfToDocument/${documentId}`,
        method: 'POST',
        payload,
        isEsignDocument: true,
        fileUpload: true
      }),
      transformResponse: (response) => response
    }),
    updateBulkDocumentStatus: builder.mutation({
      query: (params) => ({
        url: 'docs/bulk/document/updateBulkDocumentStatus',
        method: 'POST',
        params
      }),
      invalidatesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response
    }),
    uploadLinkBulkDocumentClpdf: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `bulkEsign/linkPdfToDocument/${documentId}`,
        method: 'POST',
        payload,
        isEsignDocument: true,
        fileUpload: true
      }),
      transformResponse: (response) => response
    }),
    updateBulkDocumentStatusInProcess: builder.mutation({
      query: (docId) => ({
        url: `bulkEsign/updateDocumentStatus/${docId}`,
        method: 'POST'
      }),
      invalidatesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response
    }),
    getTotalAmountByBulkId: builder.mutation({
      query: ({ payload }) => ({
        url: 'docs/bulk/document/status',
        method: 'POST',
        payload
      }),
      // invalidatesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response
    }),
    getDocumentPdfById: builder.query({
      query: (docId) => ({
        url: `docs/getESignedFile?document=${docId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    sendDocumentPdfEmail: builder.mutation({
      query: (docId) => ({
        url: `docs/sendEmailForFile?docId=${docId}`,
        method: 'POST'
      }),
      // invalidatesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response
    }),
    getAllPhysicalBluedartDocument: builder.query({
      query: (params) => ({
        url: 'docs/blueDartDocuments',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    getBluedartStatusList: builder.query({
      query: () => ({
        url: 'docs/blueDartStatus',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    addPanForStampDuty: builder.mutation({
      query: ({ payload }) => ({
        url: 'estamp/addPanForStampDuty',
        method: 'POST',
        payload
      }),
      // invalidatesTags: ['getAllBulkDocuments'],
      transformResponse: (response) => response
    }),
    getClEstampDataByDocumentId: builder.query({
      query: (params) => ({
        url: 'eStampPending/regenerateEStampCertificate',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    linkClPdfWithEstampPendingDoc: builder.mutation({
      query: ({ payload, documentId }) => ({
        url: `esign/linkPdfToDocument/${documentId}`,
        method: 'POST',
        payload,
        isEsignDocument: true,
        fileUpload: true
      }),
      invalidatesTags: ['getAllDocuments'],
      transformResponse: (response) => response
    }),
    getAllReviewConsentDocuments: builder.query({
      query: (filter) => ({
        url: 'docs/user-docs-review',
        method: 'GET',
        params: filter
      }),
      transformResponse: (response) => response?.data
    }),
    getDocumentReviewFilterStatus: builder.query({
      query: () => ({
        url: 'docs/doc-status-review',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getCreateCopyOfDocument: builder.query({
      query: (documentId) => ({
        url: `copy/createCopyOfDocument?originalDocumentId=${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response
    }),
    getTempReviewAndConsent: builder.query({
      query: (documentId) => ({
        url: `copy/getTempReviewAndConsent?documentId=${documentId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response
    })
  })
})

export const {
  useGetDocumentPdfByIdQuery,
  useGetArticleCategoryByStateQuery,
  useGetSroBranchByCityQuery,
  useLazyGetArticleTemplateByStateCatQuery,
  useGetAllArticleDataByStateQuery,
  useGetAllDocumentsQuery,
  useRemoveDocumentByIdMutation,
  useGetDocumentPreviewByIdQuery,
  useGetDocumentFieldsByIdQuery,
  useIntializeDocumentIdMutation,
  useGetDocumentFilterStatusQuery,
  useGetPaymentConfigDetailsQuery,
  useSaveDocumentMutation,
  useSaveDocumentPreviewMutation,
  useGetReviewPartyPriorityQuery,
  useGenerateDocumentPaymentIdMutation,
  useInitateDocumentPaymentCorpBaMutation,
  useUpdateDocumentPaymentStausMutation,
  useSendDocumentForReviewMutation,
  useSubmitDocumentReviewCommentMutation,
  useGetCommentsQuery,
  useAddCommentMutation,
  useAddCommentReplyMutation,
  useSubmitChangeRequestMutation,
  useGetAllBulkDocumentsQuery,
  useLazyGetAllBulkDocumentsQuery,
  useBulkDocumentUploadMutation,
  useGetAllDocumentsByBulkIdQuery,
  useLazyGetAllDocumentsByBulkIdQuery,
  useInitateBulkDocumentPaymentCorpMutation,
  useDeleteDocumentByBulkIdMutation,
  useGetBulkDocumentSampleFileQuery,
  useGetBulkDocumentIndividualDetailsByIdQuery,
  useGetBulkDocumentIndividualFileByIdQuery,
  useUploadDocumentHtmlTopdfMutation,
  useUpdateBulkDocumentStatusMutation,
  useUploadLinkBulkDocumentClpdfMutation,
  useUpdateBulkDocumentStatusInProcessMutation,
  useGetTotalAmountByBulkIdMutation,
  useSendDocumentPdfEmailMutation,
  useGetAllPhysicalBluedartDocumentQuery,
  useGetBluedartStatusListQuery,
  useAddPanForStampDutyMutation,
  useLazyGetClEstampDataByDocumentIdQuery,
  useLinkClPdfWithEstampPendingDocMutation,
  useGetAllReviewConsentDocumentsQuery,
  useGetDocumentReviewFilterStatusQuery,
  useLazyGetCreateCopyOfDocumentQuery,
  useLazyGetTempReviewAndConsentQuery
} = documentApi
