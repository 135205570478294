/* eslint-disable no-unused-vars */
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'

import fs from 'file-saver'
import { ToWords } from 'to-words'
import moment from 'moment'
// import { fetchImage } from 'pdf-lib/lib/utils/fetchImage'
// import watermark from '../assets/images/watermark.png'

/**
 *
 * @param {blob,string} pdfRes: blob in case of system generated
 * @param {string} path: which template function is  executed
 * @param {object} clResponse: response of the stamp paper
 * @param {string} digitalSignName: only in case of bulk document
 * @returns
 */
export const generateDocument = async (
  pdfRes,
  path,
  clResponse,
  digitalSignName = '',
  bulkSignPositionList = [],
  currentUserIpAddress
) => {
  const toWords = new ToWords({
    converterOptions: {
      doNotAddOnly: false
    }
  })
  // Register a Fontkit instance
  // PDFDocument.registerFontkit(Fontkit)

  let response
  let pdfArrayBuffer
  let existingPdfBytes
  if (path === 'custom' || path === 'bulkDocument') {
    response = await fetch(pdfRes) // Replace with the actual PDF link
    pdfArrayBuffer = await response.arrayBuffer()
    existingPdfBytes = pdfArrayBuffer
  } else {
    pdfArrayBuffer = await pdfRes.arrayBuffer()
    existingPdfBytes = pdfArrayBuffer
  }

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  // const imagePath = '/src/assets/MicrosoftTeams-image(1).png' // Adjust the path accordingly

  // // Fetch the image as a Blob
  const responseImage = await fetch(
    'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/1d9552fd-f06d-4d66-807f-91f67c1aa188img1.png'
  )
  const pngImageBytes = await responseImage.arrayBuffer()

  const pngImage = await pdfDoc.embedPng(pngImageBytes)

  const responseImage1 = await fetch(
    'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/691a074d-2672-4787-a2a3-2586435cc849img2.png'
  )
  const pngImageBytes1 = await responseImage1.arrayBuffer()

  const pngImage1 = await pdfDoc.embedPng(pngImageBytes1)

  const responseImage2 = await fetch(
    'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/5c47043a-7310-486f-af84-2893aa7858ffimg4.png'
  )
  const pngImageBytes2 = await responseImage2.arrayBuffer()

  const pngImage2 = await pdfDoc.embedPng(pngImageBytes2)

  // Watermark image
  const waterMark = await fetch(
    'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/b65db037-f81f-4793-a514-af651311ada4img3.png'
  )
  const waterMarkBytes = await waterMark.arrayBuffer()

  const waterMarkPng = await pdfDoc.embedPng(waterMarkBytes)

  // const fontFamily = 'Noto Sans'
  // const fontVariant = 'regular'
  // const fontResp = await fetch(`https://fonts.googleapis.com/css?family=${fontFamily}`)

  // const fontBytes = await fontResp.arrayBuffer()

  // console.log(fontBytes, 'fontBytes')
  // pdfDoc.registerFontkit(fontkit)

  // const notoFont = await pdfDoc.embedFont(fontBytes)

  // Load Noto Sans Regular font
  // const fontBytes = fs.read('NotoSans.ttf')
  // const notoFont = await pdfDoc.embedFont(fontBytes)

  // Fonts
  const timesRomanFontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
  const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
  // const notoFont = await pdfDoc.embedFont('./NotoSans.ttf')

  // insert a new page
  pdfDoc.insertPage(0)

  // Get the first page of the document
  const pages = pdfDoc.getPages()

  // New blank page that is added
  const firstPage = pages[0]

  const { width, height } = firstPage.getSize()

  const watermarkpngdims = waterMarkPng.scale(0.2)
  firstPage.drawImage(waterMarkPng, {
    x: width / 2 - 190,
    y: height / 2 - 120,
    width: watermarkpngdims.width,
    height: watermarkpngdims.height
  })

  const pngDims = pngImage.scale(0.05)
  firstPage.drawImage(pngImage, {
    x: width / 2 - 220,
    y: height - 120,
    width: pngDims.width,
    height: pngDims.height
  })

  const pngDims1 = pngImage1.scale(0.8)
  firstPage.drawImage(pngImage1, {
    x: width - 70,
    y: height - 850,
    width: pngDims1.width,
    height
    // rotate: degrees(55)
  })

  firstPage.drawImage(pngImage2, {
    x: 10,
    y: height - 850,
    width: pngDims1.width,
    height
    // rotate: degrees(-180)
  })

  firstPage.drawText('INDIA NON JUDICIAL', {
    x: width / 2 - 100,
    y: height - 60,
    size: 17,
    font: timesRomanFontBold
  })

  firstPage.drawText('Government of National Capital Territory of Delhi', {
    x: width / 2 - 140,
    y: height - 100,
    size: 15,
    font: timesRomanFontBold
  })

  // Small line under the title
  let x1 = width / 2 - 140
  for (let i = 0; i <= 7; i++) {
    firstPage.drawText('Government of National Capital Territory of Delhi', {
      x: x1,
      y: height - 104,
      size: 2,
      font: timesRomanFontBold
    })
    x1 += 40
  }

  // Sample certificate line
  firstPage.drawText('This   is   a   sample   e-Stamp   Certificate', {
    x: 40,
    y: 40,
    size: 50,
    font: timesRomanFont,
    color: rgb(0.2, 0.2, 0.2),
    rotate: degrees(55),
    opacity: 0.2
  })

  // Draw text in middle
  firstPage.drawText('e-Stamp', {
    x: width / 2,
    y: height - 166,
    size: 10,
    font: timesRomanFontBold
  })

  // Draw a line
  firstPage.drawLine({
    start: { x: 20, y: height - 165 },
    end: { x: width - 20, y: height - 165 },
    thickness: 10,
    color: rgb(0.8, 0.8, 0.8),
    opacity: 0.2
  })

  // Shcil line repeat text
  let schilX = 20
  for (let i = 0; i <= 36; i++) {
    firstPage.drawText('SHCIL', {
      x: schilX,
      y: height - 156,
      size: 4,
      color: rgb(0.8, 0.8, 0.8),
      font: timesRomanFont
    })
    schilX += 14
  }

  firstPage.drawText(`Rs.${clResponse?.stampDutyAmount}`, {
    x: width - 100,
    y: height - 150,
    size: 10,
    color: rgb(0.4, 0.4, 0.4),
    font: timesRomanFont
  })

  // Shcil line
  firstPage.drawLine({
    start: { x: 20, y: height - 155 },
    end: { x: width - 20, y: height - 155 },
    thickness: 5,
    color: rgb(0.8, 0.8, 0.8),
    opacity: 0.2
  })

  // Draw text in middle
  firstPage.drawText('Please write or type below this line', {
    x: width / 2 - 100,
    y: height - 583,
    size: 10,
    font: timesRomanFont
  })

  // Draw text in middle
  firstPage.drawText('..........................................................', {
    x: 20,
    y: height - 583,
    size: 10,
    font: timesRomanFontBold
  })

  // Draw a line
  firstPage.drawLine({
    start: { x: width / 2 - 130, y: height - 580 },
    end: { x: width - 200, y: height - 580 },
    thickness: 10,
    color: rgb(0.8, 0.8, 0.8),
    opacity: 0.2
  })

  const cerNo = clResponse?.certificateNo
  const cerNoLength = cerNo.length
  const changeCount = 6
  const loopcount = Math.trunc(cerNoLength / changeCount)
  let font = 10
  let start = 0
  let end = 7
  let certx = 200
  for (let i = 0; i < loopcount; i++) {
    const string = cerNo.slice(start, end)
    firstPage.drawText(`${string}`, {
      x: width - certx,
      y: height - 580,
      size: font,
      font: timesRomanFont,
      color: rgb(0.4, 0.4, 0.4)
    })
    const nextTotal = end + 6
    start = end
    if (cerNoLength - nextTotal < 6) {
      //  console.log(end)
      end = cerNoLength
    } else {
      end += 6
    }
    font += 1
    certx -= 36 - i
  }
  // firstPage.drawText('IN-DL67', {
  //   x: width - 200,
  //   y: height - 580,
  //   size: 10,
  //   font: timesRomanFont,
  //   color: rgb(0.4, 0.4, 0.4)
  // })
  // firstPage.drawText('320629', {
  //   x: width - 163,
  //   y: height - 580,
  //   size: 11,
  //   font: timesRomanFont,
  //   color: rgb(0.4, 0.4, 0.4)
  // })
  // firstPage.drawText('067983U', {
  //   x: width - 130,
  //   y: height - 580,
  //   size: 12,
  //   font: timesRomanFont,
  //   color: rgb(0.4, 0.4, 0.4)
  // })

  firstPage.drawText(
    '...................................................................................',
    {
      x: width - 200,
      y: height - 583,
      size: 10,
      font: timesRomanFontBold
    }
  )

  // Statutory Alert section
  firstPage.drawText('Statutory Alert:', {
    x: 80,
    y: 65,
    size: 10,
    font: timesRomanFont
  })
  firstPage.drawText(
    '1. The authenticity of this Stamp certificate should be verified at www.shcilestamp.com.',
    {
      x: 80,
      y: 55,
      size: 8,
      font: timesRomanFont
    }
  )
  firstPage.drawText(
    'Any discrepancy in the details on this Certificate and as available on the website renders it invalid.',
    {
      x: 80,
      y: 48,
      size: 8,
      font: timesRomanFont
    }
  )

  firstPage.drawText(
    '2. The onus of checking the legitimacy is on the users of the certificate.',
    {
      x: 80,
      y: 38,
      size: 8,
      font: timesRomanFont
    }
  )

  firstPage.drawText('3. In case of any discrepancy please inform the Competent Authority.', {
    x: 80,
    y: 30,
    size: 8,
    font: timesRomanFont
  })

  firstPage.drawText(
    '4. It is the responsibility of counterparties to correlate DigiDoc URN with e-Stamp Certificate Id.',
    {
      x: 80,
      y: 22,
      size: 8,
      font: timesRomanFont
    }
  )

  firstPage.drawText(
    '5. In case of physically executable document, this document will be legally compliant upon wet signatures of all the parties.',
    {
      x: 80,
      y: 15,
      size: 8,
      font: timesRomanFont
    }
  )

  // Add vertical date time line
  for (let i = 0; i < 10; i++) {
    firstPage.drawText(clResponse?.certificateIssuedDate, {
      x: 45,
      y: 20 + i * 80,
      size: 8,
      font: timesRomanFont,
      color: rgb(0.4, 0.4, 0.4),
      rotate: degrees(90)
    })
    // firstPage.drawText(firstName, {
    //   x: width - 24,
    //   y: 40 + i * 105,
    //   size: 10,
    //   font: timesRomanFontBold,
    //   rotate: degrees(90)
    // })
  }

  // Add vertical right side firstpartyname line
  const firstName = clResponse?.firstParty
  const len = firstName.length
  console.log(len)
  const loopLimit = Math.ceil((20 / len) * 7)
  console.log(loopLimit)
  for (let j = 0; j < loopLimit; j++) {
    firstPage.drawText(firstName, {
      x: width - 40,
      y: 40 + j * (5 * len),
      size: 10,
      font: timesRomanFont,
      color: rgb(0.4, 0.4, 0.4),
      rotate: degrees(90)
    })
  }

  // Add stampduty
  for (let i = 0; i < 4; i++) {
    firstPage.drawText(`Rs.${clResponse?.stampDutyAmount}`, {
      x: width - 60,
      y: 40 + 640 - i * 40,
      size: 10,
      font: timesRomanFont,
      color: rgb(0.4, 0.4, 0.4),
      rotate: degrees(-90)
    })
  }

  // Add all the cl response object
  const clResponseObject = [
    {
      label: 'Certificate No',
      size: 14,
      value: clResponse?.certificateNo,
      font: timesRomanFontBold
    },
    {
      label: 'Certificate Issued Date',
      size: 10,
      value: clResponse?.certificateIssuedDate,
      font: timesRomanFont
    },
    {
      label: 'Account Reference',
      size: 10,
      value: clResponse?.accountReference,
      font: timesRomanFont
    },
    {
      label: 'Unique Doc. Reference',
      size: 10,
      value: `${clResponse?.uniqueDocReference}/`,
      font: timesRomanFont,
      extraField: true,
      extraFieldValue: clResponse?.urn
    },
    {
      label: 'Purchased by',
      size: 10,
      value: clResponse?.purchaseBy,
      font: timesRomanFont
    },
    {
      label: 'Description of Document',
      size: 10,
      value: clResponse?.descriptionOfDocument,
      font: timesRomanFont
    },
    {
      label: 'Property Decription',
      size: 10,
      value: clResponse?.propertyDescription,
      font: timesRomanFont
    },
    {
      label: 'Consideration Price (Rs)',
      size: 10,
      value: clResponse?.considerationPrice,
      font: timesRomanFont,
      currencyWord: true,
      currencyPrice: toWords.convert(
        Number(clResponse?.considerationPrice?.replaceAll(',', '')),
        { currency: true }
      )
    },
    {
      label: 'First Party',
      size: 10,
      value: clResponse?.firstParty,
      font: timesRomanFont
    },
    {
      label: 'Second Party',
      size: 10,
      value: clResponse?.secondParty,
      font: timesRomanFont
    },
    {
      label: 'Stamp Duty Paid By',
      size: 10,
      value: clResponse?.stampDutyPaidBy,
      font: timesRomanFont
    },
    {
      label: 'Stamp Duty Amount(Rs.)',
      size: 10,
      value: clResponse?.stampDutyAmount,
      font: timesRomanFont,
      currencyWord: true,
      currencyPrice: toWords.convert(
        Number(clResponse?.stampDutyAmount?.replaceAll(',', '')),
        { currency: true }
      )
    }
  ]

  const x = 80
  let y = height - 200
  for (const item of clResponseObject) {
    firstPage.drawText(`${item?.label}  `, {
      x,
      y,
      size: item?.size,
      font: item?.font
    })

    firstPage.drawText(`: ${item.value}`, {
      x: x + 160,
      y,
      size: item?.size,
      font: item?.font
    })

    if (item?.currencyWord) {
      console.log('in if')
      firstPage.drawText(`(${item?.currencyPrice})`, {
        x: x + 170,
        y: y - 8,
        size: 10,
        font: item?.font
      })
    }
    if (item?.extraField) {
      firstPage.drawText(`${item?.extraFieldValue}`, {
        x: x + 165,
        y: y - 8,
        size: 10,
        font: item?.font
      })
    }
    // Increment the Y-coordinate for the next line
    y -= 20 // Adjust the vertical spacing as needed
  }

  firstPage.drawText(`Rs.${clResponse?.stampDutyAmount}`, {
    x: 80,
    y: height - 540,
    size: 10,
    font: timesRomanFont,
    rotate: degrees(90)
  })

  // Embed the JPG image

  // const response1 = await fetch(
  //   'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.peakpx.com%2Fen%2Fhd-wallpaper-desktop-kwdrx&psig=AOvVaw3GYBsv44aJ02wmD6e2TQGI&ust=1695393808355000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCICr9p_4u4EDFQAAAAAdAAAAABAE'
  // )

  // const imageBytes = await response1.arrayBuffer()
  // const jpgImage = await pdfDoc.embedJpg(imageBytes)

  // // Calculate the center coordinates of the page
  // const pageWidth = width
  // const pageHeight = width
  // const centerX = pageWidth / 2 - jpgImage.width / 2
  // const centerY = pageHeight / 2 - jpgImage.height / 2

  // // Draw the watermark image on the page
  // firstPage.drawImage(jpgImage, {
  //   x: centerX,
  //   y: centerY,
  //   width: jpgImage.width,
  //   height: jpgImage.height
  // })

  console.log('im here', pages)

  // Add header and footer in all the pages od the document
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i]
    const { width, height } = page.getSize()

    // Add header
    page.drawText(
            `Stamp Duty Paid Rs.${clResponse?.stampDutyAmount} vide e-Stamp Certificate Id:${clResponse?.certificateNo} StockHolding DigiDoc URN:${clResponse?.urn}`,
            {
              x: 60,
              y: height - 20,
              size: 8,
              font: timesRomanFont
            }
    )

    // Add footer

    // page.drawText(clResponse?.certificateNo, {
    //   x: width / 2,
    //   y: 10,
    //   size: 10,
    //   font: timesRomanFont
    // })

    // Only in case of bulk document sign all the document
  }
  if (path === 'bulkDocument') {
    for (let i = 0; i < bulkSignPositionList?.length; i++) {
      const page = pages[bulkSignPositionList[i]?.page]
      // const { width, height } = page.getSize()

      // Add header
      page.drawText('Digitally Signed By:-', {
        x: bulkSignPositionList[i]?.posx,
        y: bulkSignPositionList[i]?.posy,
        size: 7,
        font: timesRomanFontBold
      })
      page.drawText(`Name: ${digitalSignName}`, {
        x: bulkSignPositionList[i]?.posx,
        y: bulkSignPositionList[i]?.posy - 8,
        size: 7,
        font: timesRomanFontBold
      })
      page.drawText(`Location: IP : ${currentUserIpAddress}`, {
        x: bulkSignPositionList[i]?.posx,
        y: bulkSignPositionList[i]?.posy - 8 * 2,
        size: 7,
        font: timesRomanFontBold
      })
      page.drawText('Reason: Digidoc Signature', {
        x: bulkSignPositionList[i]?.posx,
        y: bulkSignPositionList[i]?.posy - 8 * 3,
        size: 7,
        font: timesRomanFontBold
      })

      const date = moment()

      // Format the date using the desired pattern
      const formattedDate = date.format('ddd MMM DD HH:mm:ss [IST] YYYY')

      page.drawText(`Date: ${formattedDate}`, {
        x: bulkSignPositionList[i]?.posx,
        y: bulkSignPositionList[i]?.posy - 8 * 4,
        size: 7,
        font: timesRomanFontBold
      })
      // x += 120
    }
    // page.drawText(`Digitally Signed By ${digitalSignName}`, {
    //   x: width - 200,
    //   y: 50,
    //   size: 10,
    //   font: timesRomanFontBold
    // })
  }

  console.log('width---->>>>><<>>><<<>>', width)
  const pdfDOc23 = await pdfDoc.save()
  const pdfBlob = await new Blob([pdfDOc23], { type: 'application/pdf' })
  const link = document.createElement('a')
  // link.href = URL.createObjectURL(pdfBlob)
  // link.download = 'generated.pdf'
  // link.click()
  return pdfBlob
}
