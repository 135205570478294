import {
  Box,
  Paper,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import './ReviewAndConsentTable.css'
import viewicon from '../../../../assets/actions/actionView.svg'
import info from '../../../../assets/images/info.svg'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import { DOCUEMENTFILTERSTATUS } from '../../../../constants/documentConstans'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ALERT_MESSAGE, DOCUMENTMESSAGE } from '../../../../constants/message'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
// import moment from 'moment'
import { formatDate } from '../../../../util/helper'
import palette from '../../../../theme/palette'
import AlertModal from '../../../../components/AlertModal/AlertModal'
import { useRemoveDocumentByIdMutation } from '../../../../store/services/documentServices'
import { toast } from 'react-toastify'

const ReviewAndConsentTable = ({
  documentTableData,
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [docID, setDocID] = useState('')

  // Delete document by id
  const [removeDocumentById] = useRemoveDocumentByIdMutation({})

  // Backdrop loader state
  const [backdropState, setBackdropState] = useState(false)

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))
  }
  /**
     * Handle Delete icon
     * @param {object} document: Document selected
     */
  const handleSubmitAlertModal = () => {
    console.log(docID, 'docID')
    setBackdropState(true)
    removeDocumentById(docID)
      .unwrap()
      .then((response) => {
        setBackdropState(false)

        setDocID('')
        setOpenAlertModal(false)
        toast.success(response?.message)
        // console.log('payment Status', paymentStatus)
      })
      .catch((execption) => {
        setBackdropState(false)

        console.log('DOCUMENT REVIEW FAILED:Backend Failed', execption)
      })
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Alert modal state
  const [openAlertModal, setOpenAlertModal] = useState(false)

  // Alert Modal description
  const alertModalState = {
    title: 'Alert',
    message: ALERT_MESSAGE.DELETEDOCUMENT,
    cancelButtonText: 'No',
    submitButtonText: 'I agree'
  }

  const documentTableHeaders = [
    'Document ID(URN)',
    'Article Type',
    'Template Name',
    'Document Type',
    'Status',
    'Owner Name',
    'Created At',
    'Action'
  ]

  /**
     * Handle Preview icon
     * @param {object} document: Document selected
     */
  const handlePreviewIcon = (document) => {
    console.log(document)
    if (document?.status?.id === DOCUEMENTFILTERSTATUS?.SIGNATURESREQUESTED?.ID) {
      return navigate(`/SignatureRequestedPreview/${document?.name}`, {
        state: {
          HeaderTitle: 'Preview Document'
        }
      })
    }
    if (document?.status?.id === DOCUEMENTFILTERSTATUS?.SIGNATURESREQUIRED?.ID) {
      return navigate(`/signature/${document?.name}`, {
        state: {
          HeaderTitle: 'Signature'
        }
      })
    }
    navigate(`/commentSection/${document?.name}`, {
      state: {
        HeaderTitle: 'Comment'
      }
    })
  }

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {documentTableHeaders.length > 0 &&
                                documentTableHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                        {HeaderName === 'Action' && (
                                            <>
                                                <span className="DocumentsTableActionInfoIcon">
                                                    <img
                                                        src={info}
                                                        alt="info"
                                                        onClick={handleClick}
                                                    />
                                                </span>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right'
                                                    }}>
                                                    <Box
                                                        sx={{
                                                          background: palette.primary.light,
                                                          boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                                        }}
                                                        className="documentTablePopoverBox">
                                                        <Typography
                                                            variant="darkValue"
                                                            sx={{
                                                              letterSpacing: '0.03em',
                                                              lineHeight: '30px'
                                                            }}>
                                                            {DOCUMENTMESSAGE?.DOCUMENTTABLEINFO}
                                                        </Typography>
                                                    </Box>
                                                </Popover>
                                            </>
                                        )}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {documentTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {
                            // (paginationFilter?.rowsPerPage > 0
                            //     ? documentTableData?.slice(
                            //           paginationFilter?.page * paginationFilter?.rowsPerPage,
                            //           paginationFilter?.page * paginationFilter?.rowsPerPage +
                            //               paginationFilter?.rowsPerPage
                            //       )
                            //     : documentTableData
                            // )
                            documentTableData.map((documentTableData, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell scope="row">
                                        {documentTableData?.name} ({documentTableData?.urnNumber})
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.articleCategory?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.articleTemplate?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.docDelType || 'NA'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.status?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {documentTableData?.user?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {formatDate(documentTableData?.createdOnTimeStamp)}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className="actionIcons">
                                            {(documentTableData.status?.id ===
                                                DOCUEMENTFILTERSTATUS?.CONSENTREQUIRED?.ID ||
                                                documentTableData.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.SIGNATURESREQUESTED
                                                      ?.ID ||
                                                documentTableData.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.SIGNATURESREQUIRED?.ID ||
                                                documentTableData.status?.id ===
                                                    DOCUEMENTFILTERSTATUS?.CONSENTREQUESTED
                                                      ?.ID) && (
                                                // <div className="actionIcons">
                                                <img
                                                    onClick={() =>
                                                      handlePreviewIcon(documentTableData)
                                                    }
                                                    src={viewicon}
                                                    alt="viewicon"
                                                    title="Preview document"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                // </div>
                                            )}
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {documentTableData.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* Pagination */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* Alert confirmation modal */}
            <AlertModal
                openAlertModal={openAlertModal}
                handleAlertModalClose={() => setOpenAlertModal(false)}
                handleSubmitAlertModal={handleSubmitAlertModal}
                alertModalState={alertModalState}
            />
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Paper>
  )
}

export default ReviewAndConsentTable
ReviewAndConsentTable.propTypes = {
  documentTableData: PropTypes.array,
  isLoading: PropTypes.Boolean,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
