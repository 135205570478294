export const STAMPPAPERIMAGELINKS = {
  EMBLEM: 'https://storage.googleapis.com/digidocapibeuat/staticcontentDND/emblem.png',
  RIGHTBORDER: 'https://storage.googleapis.com/digidocapibeuat/staticcontentDND/rightBorder.png',
  LEFTBORDER: 'https://storage.googleapis.com/digidocapibeuat/staticcontentDND/leftBorder.png',
  CENTEREMBLEM: 'https://storage.googleapis.com/digidocapibeuat/staticcontentDND/centerEmblem.png'
}

export const STAMPPAPER = {
  HEADERTITILE: 'INDIA NON JUDICIAL',
  SAMPLECERTIFICATELINE: '          Details of Stamp Duty Payment',
  ESTAMP: 'e-Stamp',
  DEFECITESTAMP: 'Deficit e-Stamp Duty',
  SHCIL: 'SHCIL',
  RUPPEESYMBOL: 'Rs',
  BELOWLINE: 'Please write or type below this line',
  STATUARYALERT: 'Statutory Alert:',
  DONOTPRINT: 'DO NOT PRINT DOCUMENT ON THIS PAGE',
  CLAUSE1:
        '1. The authenticity of this Stamp certificate should be verified at www.shcilestamp.com.',
  CLAUSEPART1:
        '    Any discrepancy in the details on this Certificate and as available on the website renders it invalid.',
  CLAUSE2: '2. The onus of checking the legitimacy is on the users of the certificate.',
  CLAUSE3: '3. In case of any discrepancy please inform the Competent Authority.',
  CLAUSE4:
        '4. Each executed document has unique identification number and is tagged with unique eStamp number. ',
  CLAUSEPART4:
        '    These details are available on each page of the document. Parties are responsible to verify and correlate the same.',
  CLAUSE5:
        '5. In case of physically executable documents, the eStamp certificate provided to the user needs to be attached with the document ',
  CLAUSEPART5: '    and executed by putting wet signature of all the parties on each page.'
}
