import React from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../../../theme/palette'
import { useGetCodeChallengeQuery } from '../../../../store/services/commonServices'
import { MESSAGE_CONFIRMATION } from '../../../../constants/message'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  background: palette.primary.light,
  boxShadow: `6px 11px 60px 12px rgba(${palette.primary.dark}, 0.06)`,
  borderRadius: '10px',
  p: 4
}

const PanModal = ({ openPanModal, handlePanModalClose }) => {
  // const [verifyPanForm, setVerifyPanForm] = useState({
  //   otp: '',
  //   errors: {
  //     panCard: 'error',
  //     name: 'error',
  //     fatherName: 'noerror',
  //     dateOfBirth: 'noerror'
  //   }
  // })

  // Restriction context api
  // const { restrictionValues } = useContext(RestrictionContext)
  // const [backdropState, setBackdropState] = useState(false)

  // const [otp, setOtp] = useState(false)
  const codeChallenge = useGetCodeChallengeQuery({})
  // const [addPanKyc] = useAddPanMutation({})
  // const [verifyPanOtp] = useVerifyOtpPanMutation({})

  // const SubmitPanDetails = () => {
  //   if (
  //     verifyPanForm?.errors?.panCard === 'noerror' &&
  //           verifyPanForm?.errors?.name === 'noerror' &&
  //           verifyPanForm?.errors?.dateOfBirth === 'noerror'
  //   ) {
  //     setBackdropState(true)
  //     const dataToSend = {
  //       panNumber: verifyPanForm?.panCard,
  //       name: verifyPanForm?.name,
  //       fatherName: verifyPanForm?.fatherName,
  //       dob: moment(verifyPanForm?.dateOfBirth).format('DD-MM-YYYY')
  //     }
  //     addPanKyc({ payload: dataToSend })
  //       .unwrap()
  //       .then((response) => {
  //         setBackdropState(false)
  //         toast.success(response?.message)
  //         const digiLockerForm = document.getElementById('URL')
  //         digiLockerForm.submit()
  //         //  setIsPanSaved(true)
  //         // setOtp(true)
  //       })
  //       .catch((execption) => {
  //         setBackdropState(false)

  //         console.log('EXCEPTION:CHECK PAN KYC', execption)
  //       })
  //   }
  // }

  // const handlePancardChange = (event) => {
  //   const err = validator(event?.target?.name, event?.target?.value)

  //   setVerifyPanForm((prev) => ({
  //     ...prev,
  //     [event.target.name]: event?.target?.value?.toUpperCase(),
  //     errors: {
  //       ...prev.errors,
  //       [event.target.name]: err
  //     }
  //   }))
  // }

  // const handleNameChange = (event) => {
  //   const err = validator(event?.target?.name, event?.target?.value)

  //   setVerifyPanForm((prev) => ({
  //     ...prev,
  //     [event.target.name]: event?.target?.value.replace(/\s/gy, ''),
  //     errors: {
  //       ...prev.errors,
  //       [event.target.name]: err
  //     }
  //   }))
  // }

  // const handleFatherNameChange = (event) => {
  //   const err = validator(event?.target?.name, event?.target?.value)

  //   setVerifyPanForm((prev) => ({
  //     ...prev,
  //     [event.target.name]: event?.target?.value.replace(/\s/gy, ''),
  //     errors: {
  //       ...prev.errors,
  //       [event.target.name]: err
  //     }
  //   }))
  // }

  // const handleDateOfBirthChange = (date) => {
  //   // Calculate today's date
  //   const today = new Date()

  //   const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
  //   if (date < minDate) {
  //     setVerifyPanForm((prev) => ({
  //       ...prev,
  //       dateOfBirth: date
  //     }))
  //   } else {
  //     toast.error(errormessages.DOBERROR)
  //   }
  // }

  /*
  const submitOtpForPan = () => {
    const dataToSend = {
      otp: verifyPanForm?.otp
    }
    verifyPanOtp({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        toast.success(response?.message)
        handlePanModalClose()
      })
      .catch((execption) => {
        console.log('EXCEPTION:VERIFY PAN OTP', execption)
      })
  }
  */
  console.log('Code Challenge======>', codeChallenge.data)
  return (
        <Modal
            open={openPanModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                {/* <Grid container justifyContent={'flex-end'}>
                    <img
                        src={cross}
                        alt=""
                        height="21px"
                        width="21px"
                        onClick={handlePanModalClose}
                        style={{ cursor: 'pointer' }}
                    />
                </Grid> */}

                {/* <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      SubmitPanDetails()
                    }}>
                    {/* <div className="aadharmodal_box">
                        <Typography
                            variant="labelLarge"
                            component="div"
                            sx={{ fontSize: '25px', marginBottom: '20px', fontWeight: 600 }}>
                            PAN Verification
                        </Typography>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ lineHeight: '17px', marginBottom: '10px' }}>
                            Enter PAN
                        </Typography>
                        <TextField
                            error={
                                !(
                                  verifyPanForm?.errors?.panCard === 'error' ||
                                    verifyPanForm?.errors?.panCard === 'noerror'
                                )
                            }
                            helperText={
                                verifyPanForm?.errors?.panCard === 'error' ||
                                verifyPanForm?.errors?.panCard === 'noerror'
                                  ? ''
                                  : verifyPanForm?.errors?.panCard
                            }
                            inputProps={{
                              type: 'text',
                              maxlength: 10,
                              minlength: 10
                            }}
                            required
                            type="text"
                            id="panCard"
                            name="panCard"
                            value={verifyPanForm?.panCard || ''}
                            onChange={handlePancardChange}
                            placeholder="Enter here"
                            fullWidth
                            style={{ marginBottom: '20px' }}
                        />
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ lineHeight: '17px', marginBottom: '10px' }}>
                            Enter Name
                        </Typography>
                        <TextField
                            error={
                                !(
                                  verifyPanForm?.errors?.name === 'error' ||
                                    verifyPanForm?.errors?.name === 'noerror'
                                )
                            }
                            helperText={
                                verifyPanForm?.errors?.name === 'error' ||
                                verifyPanForm?.errors?.name === 'noerror'
                                  ? ''
                                  : verifyPanForm?.errors?.name
                            }
                            inputProps={{
                              type: 'text',
                              maxlength: 40
                            }}
                            required
                            type="text"
                            id="name"
                            name="name"
                            value={verifyPanForm?.name || ''}
                            onChange={handleNameChange}
                            placeholder="Enter here"
                            fullWidth
                            style={{ marginBottom: '20px' }}
                        />
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ lineHeight: '17px', marginBottom: '10px' }}>
                            Enter Father&apos;s Name
                        </Typography>
                        <TextField
                            error={
                                !(
                                  verifyPanForm?.errors?.fatherName === 'error' ||
                                    verifyPanForm?.errors?.fatherName === 'noerror'
                                )
                            }
                            helperText={
                                verifyPanForm?.errors?.fatherName === 'error' ||
                                verifyPanForm?.errors?.fatherName === 'noerror'
                                  ? ''
                                  : verifyPanForm?.errors?.fatherName
                            }
                            inputProps={{
                              type: 'text',
                              maxlength: 40
                            }}
                            required
                            type="text"
                            id="fatherName"
                            name="fatherName"
                            value={verifyPanForm?.fatherName || ''}
                            placeholder="Enter here"
                            fullWidth
                            style={{ marginBottom: '20px' }}
                        />
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ lineHeight: '17px', marginBottom: '10px' }}>
                            Enter Date Of Birth
                        </Typography>

                        <DatePickerComponent
                            date={verifyPanForm.dateOfBirth}
                            placeholder="Enter here"
                            setDate={handleDateOfBirthChange}
                            maxDate={new Date()}
                            label=""
                        />
                        <Button
                            type="submit"
                            variant="primary"
                            sx={{ width: '120px', marginTop: '10px' }}>
                            Save
                        </Button>
                    </div>
                </form> */}
                <form
                    id="URL"
                    name="URL"
                    method="GET"
                    action="https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize">
                    <div style={{ marginTop: '20px' }}>
                        <Typography variant="darkValueLarge">
                            {MESSAGE_CONFIRMATION?.CONFIRMPANDETAILS}
                        </Typography>

                        <Button
                            variant="cancelButton"
                            sx={{ width: '120px', marginTop: '30px', marginRight: '10px' }}
                            onClick={handlePanModalClose}>
                            No
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            sx={{ width: '120px', marginTop: '30px' }}>
                            Yes
                        </Button>

                        <input type="hidden" name="response_type" value={'code'} />
                        <input
                            type="hidden"
                            name="client_id"
                            value={process.env.REACT_APP_DIGILOCKER_AZURE_CLIENT_ID}
                        />
                        <input
                            type="hidden"
                            name="redirect_uri"
                            value={process.env.REACT_APP_REDIRECT_URL_AZURE}
                        />
                        <input type="hidden" name="state" value={'abcd123'} />
                        <input type="hidden" name="code_challenge" value={codeChallenge.data} />
                        <input type="hidden" name="code_challenge_method" value="S256" />
                        <input type="hidden" name="req_doctype" value="PANCR,ADHAR" />
                        <input type="hidden" name="acr" value="aadhaar" />
                    </div>
                </form>

                {/* )} */}

                {/* {otp && (
                    <form
                        onSubmit={(e) => {
                          e.preventDefault()
                          submitOtpForPan()
                        }}>
                        <div className="aadharmodal_box">
                            <Typography
                                variant="labelLarge"
                                component="div"
                                sx={{ fontSize: '25px', marginBottom: '20px', fontWeight: 600 }}>
                                Enter OTP
                            </Typography>
                            <Typography
                                variant="darkValue"
                                component="div"
                                sx={{ lineHeight: '17px', marginBottom: '10px' }}>
                                Enter OTP sent to your phone number ending with **57
                            </Typography>
                            <TextField
                                type="number"
                                id="otp"
                                name="otp"
                                inputProps={{
                                  type: 'text',
                                  // inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  maxlength: 6,
                                  minlength: 6
                                }}
                                required
                                value={verifyPanForm?.otp}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                        PATTERN?.NUMBERONLY.test(e?.target?.value)
                                  ) {
                                    handlePancardChange(e)
                                  }
                                }}
                                placeholder="enter here"
                                fullWidth
                                style={{ marginBottom: '20px' }}
                            />
                            <Button variant="primary" sx={{ width: '120px' }} type="submit">
                                Verify
                            </Button>
                        </div>
                    </form>
                )} */}

                {/* {backdropState && <BackdropLoader backdropState={backdropState} />} */}
            </Box>
        </Modal>
  )
}

export default PanModal

PanModal.propTypes = {
  openPanModal: PropTypes.bool,
  handlePanModalClose: PropTypes.func
}
