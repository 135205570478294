export const MESSAGE_CONFIRMATION = {
  TEXT: 'Are you sure you want to exit?',
  SIGNMODALMESSAGE:
        'Thank you your payment has been successfully confirmed, Please continue with the signature process',
  DIGITALSIGNCONFIRMMESSAGE:
        'Please make sure that you have a valid and functional Digital Signature with you. Once your payment is done, signature mode cannot be changed nor the payment made can be refunded.',
  KYCCOMPLETEMESSAGE: 'Please complete your KYC with DigiLocker in order to use the system.',
  ACTIONDOCUMENTS:
        'There are some documents waiting for your actions. Please visit the Review and Consent tab.',
  SOCIALLOGINNAME:
        'Digidoc Profile name should be as per name on Aadhar Card. It will be displayed along with digital signature on the document.',
  DIGILOCKERINITIATIONALERT:
        'Make sure your Aadhaar and PAN are connected with DigiLocker. If not please first connect PAN and Aadhaar with Digi Locker than proceed with this option. Please make sure you select PAN and Aadhaar checkboxes on the DigiLocker consent screen',
  CONFIRMPANDETAILS: 'Kindly confirm having valid PAN number.'
}

export const ALERT_MESSAGE = {
  CREATEDOCUMENT:
        'Please make sure your document is ready for processing as once initiated the payment will not be refunded.',
  DELETETICKET: 'Are you sure you want to delete this ticket',
  CANCELSUBSCRIPTION:
        'Cancel subscription will be effective on last date of current subscribed plan.',
  DELETEDOCUMENT: 'Are you sure you want to delete your document?',
  STATUSCHANGE: 'Are you sure you want to change the status of the user',
  PINCODESERVICEABLE: 'Delivery services are available in your area.',
  PINOCDENOTSERVICEABLE:
        'Delivery services are not available in your area. Select some other address.',
  PINCODETITLE: 'Kindly Note',
  CLERRORTITLE: 'Error',
  DIGITALSIGNERRORMODALTITLE: 'Kindly Note',
  DOCUMENTCORRECTIONREQUIRED: 'There were some error in the previous document',
  REVIEWCONSENTPRIORITY: 'Please add all the priority in the sequence',
  CANCELSUBSCRIPTIONREFUND:
        "You haven't used any document in this subscription. You will not receive any refunds.",
  KYCGUIDEMODALTITLE: 'KYC Required',
  PENDINGACTIONS: 'Pending Actions',
  IMPORTANTNOTE: 'Alert !',
  GENERATEINVOICENOW: 'Would you like to generate an invoice now?',
  CONFIRMINVOICEGENERATIONNOW:
        'I/We understand that Subscription amount is non-refundable once Invoice is generated .',
  CONFIRMINVOICEGENERATIONLATER:
        'I/We understand that Subscription amount is non-refundable once Invoice is generated or first transaction is done or subscription period ends',
  DIGILOCKERAUTHORIZATIONSUCCESSFULL: 'Authorization Successfull!',
  DIGILOCKERAUTHORIZATIONFAILED: 'Authorization Failed!',
  DIGILOCKERACKFORCONSENT:
        'Thank you for providing consent for Digi Locker. We will proceed with your KYC. You can check your KYC status on dashboard.',
  REVIEWCONSENTCOMPLETEINFORMATION: 'Please fill all the information for the reviewers.'
}

export const HOME_MESSAGE = {
  CORPORATE:
        'Your account is under review. Our HO department will check all the information you have uploaded. We will notify you over email as soon as an action on your request is taken.',
  INDIVIDUAL: 'Please activate your profile by doing KYC. '
}
export const CONSENTMESSAGE = [
  '1.) These checkboxes will help you send consent requests and signature requests, respectively, to the parties and concerned people linked with the document.',
  '2.) Upon selection of the required consent, the system will send an email/SMS request to the concerned person asking for their consent.',
  '3.) Upon selecting the "Signatures Required" checkbox, the system will sequentially send signature requests to the concerned individuals, prompting them to sign the document. Note that the signature process will only commence after the payment for the document has been processed.',
  '4.) Note: All parties whose consent is requested for the document must also be added to the signature request.'
]

export const PRIORITYMESSAGE = [
  '1.) Please select the priority for signatures. For ex: Party with priority first will get the signature request first prior to any other party. Similarly, Party with second priority will get signature request after the first priority completes the signature process.'
]

export const PREFFEREDSIGNMODEMESSAGE = ['Keep your Aadhar/DSC ready handy']

// export const CONSENTMESSAGE={
//       CONSENTESIGN:'These checkboxes will help you to send consent request and signature request respectively to the parties and conserned people linked with the document'

// Upon selection of the consent required, system will send email/sms request to the conserned person for providing their consent.ALERT_MESSAGE

// Upon selection of the signatures required checkboxClasses, system will send signatures requst to the concerned person one by one for getting their signatures on the document. NOTE - the signatre process will only start after the payment for the document is processed.ALERT_MESSAGE

// NOTE - All the parties whos consent is requested for the document will have to be added to the signatures request as WEll}
export const SUBSCRIPTIONCANCELMEESAGE = (documentCount) => {
  return `You have used ${documentCount} document in this subscription. We can't offer a refund at this time, you can continue to enjoy all subscription benefits until the end of your plan's term`
}

export const DOCUMENTMESSAGE = {
  DOCUMENTTABLEINFO:
        'Document download and email option will be available only for the period of 60 days for free storage documents and 5 years for paid storage documents. After that the document will be deleted.',
  CERTIFICATEGENERATED: 'Certificate Generated Successfully'
}

export const STAMPDUTYINFO = (minimumStampDuty) => {
  return [`Minimum stamp duty allowed is ₹${minimumStampDuty}`]
}

export const COPYDOCUMENTSUCCESSFULMESSAGE = (documentId) => {
  return [
        `A copy of your document has been created successfully and added to the top of my documents list. Please refer to the document with ID-${documentId}`
  ]
}

export const INFORMATION_MESSAGE = {
  NAME_MESSAGE: 'Please enter name as per Aadhaar name.'
}

export const TOAST_MESSAGE = {
  MONEY_ADDED_TO_WALLET: 'Money added to Wallet successfully.',
  BULK_TRANSACTION_DELETED: 'Bulk transaction deleted successfully.',
  REVIEW_USER_DELETED: 'Deleted Successfully.'
}
