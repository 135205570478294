/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemIcon,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'
import * as XLSX from 'xlsx'
import React, { useContext, useEffect, useState } from 'react'
import MenuProps from '../../StyledComponents/SelectStyles/SelectStyles'
import './index.css'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import uploadicon from '../../assets/images/uploadicon.svg'
import headarrow from '../../assets/images/headarrow.svg'
import UploadDocsModal from '../../components/UploadDocsModal/UploadDocsModal'
import { getSession } from '../../util/helper'
import {
  useConvertDocToPdfMutation,
  useGetCityQuery,
  useGetStateQuery
} from '../../store/services/userServices'
import {
  useBulkDocumentUploadMutation,
  useGetAllArticleDataByStateQuery,
  useGetArticleCategoryByStateQuery,
  useGetBulkDocumentSampleFileQuery,
  useGetSroBranchByCityQuery,
  useIntializeDocumentIdMutation
} from '../../store/services/documentServices'
import { useNavigate } from 'react-router-dom'
import {
  BULKFILESACCEPTED,
  DOCDOCXTYPE,
  DOCTYPE,
  PATTERN,
  PDFTYPE,
  ROLES,
  SAMPLEDOWNLOADFILE,
  USER_ROLE
} from '../../constants/enum'
import downloadIcon from '../../assets/images/downloadIcon.svg'
import { ProfileContext } from '../../contextapi/ProfileContext'
import { exportExcel, readAsJson } from '../../util/excelService'
import Dropzone from '../../components/Dropzone/Dropzone'
import PropTypes from 'prop-types'
import CloseImg from '../../assets/images/closeImg.svg'
import { toast } from 'react-toastify'
import CustomCard from '../../components/CustomCard/index'
import palette from '../../theme/palette'
import { RestrictionContext } from '../../contextapi/RestrictionContext'
import errormessages from '../../constants/errormessages'
import BackdropLoader from '../../components/BackdropLoader/BackdropLoader'
import NotesModal from '../../components/NotesModal/NotesModal'
import { useLazyVerifyPinCodeServiceQuery } from '../../store/services/commonServices'
import { StateContext } from '../../contextapi/StateContext'
import CommonMessageModal from '../../components/commonMessageModal/CommonMessageModal'
import { ALERT_MESSAGE, CREATEDOCUMENTMESSAGE, DOCUMENTMESSAGE } from '../../constants/message'
import TemplateTable from './components/TemplateTable'
import info from '../../assets/images/info.svg'
import CommonPreviewIframe from '../../components/CommonPreviewIframe/CommonPreviewIframe'
import PreviewDocumentPdf from './ESignaturePage/PreviewDocumentPdf/PreviewDocumentPdf'
import PreviewDocumentPdfModal from './components/PreviewDocumentPdfModal/PreviewDocumentPdfModal'
import { PARTYCOUNT, TEMPLATETYPE } from '../../constants/documentConstans'
import CustomTooltip from '../../components/Tooltip/CustomTooltip'
import DocumentCopiesEntryModal from './components/DocumentCopyEntryModal/DocumentCopiesEntryModal'
import { error } from 'pdf-lib'
import PartyCountModal from './components/PartyCountModal/PartyCountModal'

// File name component
function Files ({ myFiles, fileName, removeFile }) {
  return (
        <li style={{ marginRight: '10px' }}>
            <Grid className="uploadFile">
                <Grid>
                    <Typography sx={{ fontSize: '13px', fontWeight: 600, wordBreak: 'break-all' }}>
                        {myFiles[`${fileName}`]?.path}
                    </Typography>
                </Grid>
                <img
                    src={CloseImg}
                    onClick={() => {
                      removeFile(fileName)
                    }}
                    className="removeUploadedFile"
                    alt="Cancel"
                />
            </Grid>
        </li>
  )
}

Files.propTypes = {
  myFiles: PropTypes.object,
  fileName: PropTypes.string,
  removeFile: PropTypes.func
}

const CreateDocument = () => {
  // Navigate
  const navigate = useNavigate()

  const [radioButtonTemplate, setRadioButtonTemplate] = useState('systemGenerated')

  const [radioButtonUpload, setRadioButtonUpload] = useState('singleDocument')

  const [documentType, setDocumentType] = useState()

  // Backdrop loader state
  const [backdropState, setBackdropState] = useState(false)

  // Handle Preview Custom upload file thumbnail Modal state
  const [previewDocumentPdfModalState, setPreviewDocumentPdfModalState] = useState({
    open: false
  })

  const [objectUrl, setobjectUrl] = useState('')

  // Handle pincode notes modal state
  const [notesModalState, setNotesModalState] = useState({})

  const [radioButtonPartyCount, setRadioButtonPartyCount] = useState(PARTYCOUNT?.SINGLEPARTY?.id)

  const [openPartyCountModal, setOpenPartyCountModal] = useState(false)

  // Restriction context api
  const { restrictionValues, handleRestriction } = useContext(RestrictionContext)

  // Files uploaded
  const [myFiles, setMyFiles] = useState({})

  // Profile context api
  const { profile: profileData } = useContext(ProfileContext)

  const userInfo = JSON.parse(getSession('user'))

  const [intializeForm, setIntializeForm] = useState({
    state: profileData?.state?.id,
    city: profileData?.city?.id,
    articleType: '',
    pinCode: '',
    pinCodeStatus: false,
    template: '',
    copies: '1',
    partyCount: PARTYCOUNT?.SINGLEPARTY?.id
  })

  // Alert Modal description
  const documentCopyModalState = {
    label: 'Number of counterparts (copies) required',
    submitButtonText: 'Proceed',
    copies: intializeForm?.copies
  }
  const [openCopiesTooltip, setCopiesTooltip] = useState(false)
  const handleCopiesTooltipOpen = () => setCopiesTooltip(true)
  const handleCopiesTooltipClose = () => setCopiesTooltip(false)
  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }

  // Get cities according to the state selected
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: intializeForm?.state, document: true },
    {
      skip: !intializeForm?.state
    }
  )

  // Convert doc to pdf
  const [convertDocToPdf] = useConvertDocToPdfMutation({})

  // Get Sro Branch according to the  city selected
  const { data: sroBranchList = [] } = useGetSroBranchByCityQuery(intializeForm?.city, {
    skip: !intializeForm?.city
  })

  // State tooltip
  const [openStateTooltip, setStateTooltip] = useState(false)

  const handleStateTooltipOpen = () => setStateTooltip(true)
  const handleStateTooltipClose = () => setStateTooltip(false)

  // City tooltip
  const [openCityTooltip, setCityTooltip] = useState(false)

  const handleCityTooltipOpen = () => setCityTooltip(true)
  const handleCityTooltipClose = () => setCityTooltip(false)

  // Nearest Location tooltip
  const [openNLocationTooltip, setNLocationTooltip] = useState(false)

  const handleNLocationTooltipOpen = () => setNLocationTooltip(true)
  const handleNLocationTooltipClose = () => setNLocationTooltip(false)

  // Nearest tooltip
  const [openEStampTooltip, setEStampTooltip] = useState(false)
  const handlEStampTooltipOpen = () => setEStampTooltip(true)
  const handleEStampTooltipClose = () => setEStampTooltip(false)

  // Select Template Type
  const [openTempTypeTooltip, setTempTypeTooltip] = useState(false)
  const handlTempTypeTooltipOpen = () => setTempTypeTooltip(true)
  const handleTempTypeTooltipClose = () => setTempTypeTooltip(false)
  const [pinCodeInformation, setPinCodeInformation] = useState({})
  useEffect(() => {
    console.log('im here in create doc')
    setIntializeForm({
      state: profileData?.state?.id,
      city: profileData?.city?.id,
      articleType: '',
      pinCode: '',
      pinCodeStatus: false,
      copies: '1'
    })
  }, [profileData])

  const [fileUploaded, setFileUploaded] = useState('')

  const deliveryTypeListIndividual = [
    {
      label: 'Physical',
      value: DOCTYPE?.PHYSICAL?.ID
    },
    {
      label: 'Digital',
      value: DOCTYPE?.DIGITAL?.ID
    }
  ]

  const deliveryTypeListCorpBa = [
    {
      label: 'HardCopy',
      value: DOCTYPE?.HARDCOPY?.ID
    },
    {
      label: 'Digital',
      value: DOCTYPE?.DIGITAL?.ID
    }
  ]

  const deliveryTypeList =
        profileData?.role === ROLES?.INDIVIDUAL
          ? deliveryTypeListIndividual
          : deliveryTypeListCorpBa

  const optionsArray = [TEMPLATETYPE?.SYSTEMGENERATED, TEMPLATETYPE?.CUSTOMUPLOAD]

  const partyCountOptions = [PARTYCOUNT?.SINGLEPARTY, PARTYCOUNT?.MULTIPLEPARTY]

  const optionsArrayBulk = [
    {
      label: 'Single Document',
      value: 'singleDocument'
    },
    {
      label: 'Bulk Upload',
      value: 'bulkUpload'
    }
  ]

  const handleRadioButtonTemplate = (event) => {
    console.log('EVENT+++++>>>>>', event.target.value)
    setRadioButtonTemplate(event.target.value)
  }

  const handleRadioButtonUpload = (event) => {
    setRadioButtonUpload(event.target.value)
  }

  const handleSelectDocumentType = (event) => {
    setDocumentType(event.target.value)
  }

  // Upload modal state
  const [openUploadModal, setOpenUploadModal] = useState(false)

  const { data: stateArray = [] } = useGetStateQuery({
    document: 'system'
  })
  // get all states

  // const { stateList: stateArray = [] } = useContext(StateContext)

  // Article template for system generated
  const { data: articleTemplateResponse = {} } = useGetAllArticleDataByStateQuery(
    {
      stateId: intializeForm?.state,
      docType: intializeForm?.deliveryType,
      tempType:
                radioButtonTemplate === TEMPLATETYPE?.SYSTEMGENERATED?.value
                  ? TEMPLATETYPE?.SYSTEMGENERATED?.id
                  : TEMPLATETYPE?.CUSTOMUPLOAD?.id
    },
    {
      skip: !intializeForm?.deliveryType
    }
  )

  // Article type or category in custom upload
  const { data: articleTypeArray = [] } = useGetArticleCategoryByStateQuery(
    intializeForm?.state,
    {
      skip: !intializeForm?.state
    }
  )

  const { data: sampleFile = {} } = useGetBulkDocumentSampleFileQuery(
    {
      state: intializeForm?.state,
      category: intializeForm?.category,
      template: intializeForm?.template
    },
    {
      skip: !(
        (intializeForm?.state && intializeForm?.template)
      // && radioButtonUpload === 'bulkUpload'
      )
    }
  )

  const [checkPinCode] = useLazyVerifyPinCodeServiceQuery({})

  const [initializeDocument] = useIntializeDocumentIdMutation()

  const [bulkUploadDocument] = useBulkDocumentUploadMutation()

  const handleUploadModalClose = () => {
    // setFileUploaded('')
    setOpenUploadModal(false)
  }

  const handleInitializeFormChange = (event) => {
    console.log('INITIALIZE FORM ', event.target.name, event.target.value)
    setIntializeForm((prev) => ({
      ...prev,

      [event.target.name]: event.target.value
    }))
    if (
      event?.target?.name === 'deliveryType' &&
            event.target.value === DOCTYPE?.DIGITAL?.ID.toString()
    ) {
      setPinCodeInformation({})
      // set pinCodeStatus to true
      setIntializeForm((prev) => ({
        ...prev,
        pinCodeStatus: false
      }))
      // setOpenDocumentCopyEntryModal(true)
    } else {
      setIntializeForm((prev) => ({
        ...prev,
        copies: 1
      }))
    }

    if (
      event?.target?.name === 'deliveryType' &&
            event.target.value === DOCTYPE?.HARDCOPY?.ID.toString()
    ) {
      setPinCodeInformation({
        error: 'noerror'
      })
    }
    if (event?.target?.name === 'deliveryType') {
      setOpenPartyCountModal(true)
    }
    console.log(intializeForm)
  }

  // Check if the user has completed the profile and other checks
  const handleCreateDocumentRestriction = () => {
    const errormessage = handleRestriction()
    if (errormessage) return errormessage
    else if (
      profileData?.role !== ROLES?.INDIVIDUAL &&
            Number(restrictionValues?.documentConsumed) + 1 >
                Number(restrictionValues?.documentAllowed)
    ) {
      return errormessages?.MAXIMUMALLOWEDDOCUMEN
    }
  }

  const isAllowedDocumentError = handleCreateDocumentRestriction()

  const handleStateChange = (event) => {
    setIntializeForm((prev) => ({
      ...prev,
      // articleType: '',
      [event.target.name]: event.target.value,
      city: '',
      sroBranch: ''
    }))
  }

  const handleCustomUploadTemplateError = () => {
    console.log(intializeForm?.deliveryType)
    if (!intializeForm?.deliveryType) {
      return errormessages?.DOCUMENTDELIVERYTYPEREQUIREDERROR
    } else if (
      intializeForm?.deliveryType === DOCTYPE?.PHYSICAL?.ID.toString() &&
            !intializeForm?.pinCodeStatus
    ) {
      return errormessages?.PINCODECHECKREQUIRED
    } else if (!intializeForm?.sroBranch) {
      return errormessages?.SROBRANCHREQUIRED
    } else {
      return ''
    }
  }
  // Check if the user profile and all other restrictions checks are completed, then open the upload document modal
  const handleOpenUploadModal = async () => {
    // const isAllowedDocumentError = handleCreateDocumentRestriction()
    console.log('isAllowedDocumentErrorisAllowedDocumentError', isAllowedDocumentError)
    if (isAllowedDocumentError) {
      return toast.error(isAllowedDocumentError)
    } else {
      const error = handleCustomUploadTemplateError()
      console.log('im here', error)
      if (error) {
        toast.error(error)
      } else {
        setOpenUploadModal(true)
      }
    }
  }

  const handleSystemGeneratedTemplateError = () => {
    const errormessage = handleRestriction()
    if (errormessage) return errormessage
    // Only for Corporate and business user
    else if (
      profileData?.role !== ROLES?.INDIVIDUAL &&
            Number(restrictionValues?.documentConsumed) + 1 >
                Number(restrictionValues?.documentAllowed)
    ) {
      return errormessages?.MAXIMUMALLOWEDDOCUMEN
    } else if (!intializeForm?.sroBranch) {
      return errormessages?.SROBRANCHREQUIRED
    } else if (
      intializeForm?.deliveryType === DOCTYPE?.PHYSICAL?.ID.toString() &&
            !intializeForm?.pinCodeStatus
    ) {
      return errormessages?.PINCODECHECKREQUIRED
    } else {
      return ''
    }
  }

  /**
     * Check if the user profile and all other restrictions checks are completed, then intitalize the document
     * @param {object} template : Selected template
     */
  const handleFormSubmit = (template) => {
    console.log(template, 'templatetemplate')
    const errormesage = handleSystemGeneratedTemplateError()
    if (errormesage) {
      return toast.error(errormesage)
    } else {
      setOpenUploadModal(false)
      setBackdropState(true)
      const dataToSend = {
        state: intializeForm?.state,
        city: intializeForm?.city,
        category:
                    radioButtonTemplate === TEMPLATETYPE?.SYSTEMGENERATED?.value
                      ? template?.categoryId
                      : intializeForm?.category,
        pinCode: intializeForm?.pinCode,
        sroId: intializeForm?.sroBranch,
        docType: intializeForm?.deliveryType,
        template:
                    radioButtonTemplate === TEMPLATETYPE?.SYSTEMGENERATED?.value
                      ? template?.id
                      : intializeForm?.template,
        file: fileUploaded,
        copies: intializeForm?.copies || '1',
        partyCount: radioButtonPartyCount
      }
      initializeDocument({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          console.log(response, radioButtonUpload, 'initialize')
          if (fileUploaded && radioButtonTemplate === 'customUpload') {
            navigate(`/documentDetails/${response?.userDocument}`, {
              state: {
                HeaderTitle: 'Create Document'
              }
            })
          } else {
            navigate(`/customDocument/${response?.userDocument}`, {
              state: {
                HeaderTitle: 'Create Document'
              }
            })
          }
          console.log(response, 'response')
        })
        .catch((exception) => {
          setBackdropState(false)

          console.log('EXCEPTION:DOCUMENT INITIALIZED', exception)
        })
    }
  }

  // Handle show thumbnail (preview) of the document that is uploaded
  // If doc,docx the convert it to pdf and then show
  const handleUploadCustomDocumentForm = () => {
    console.log('fileUploaded?.typefileUploaded?.type', fileUploaded?.type)
    const fileType = fileUploaded?.type
    if (fileType === PDFTYPE) {
      const fileUrl = URL.createObjectURL(fileUploaded)
      setPreviewDocumentPdfModalState({ open: true, fileUrl })
      setOpenUploadModal(false)
      console.log('im in pdf')
    } else if (DOCDOCXTYPE.includes(fileType)) {
      setBackdropState(true)
      const dataToSend = {
        file: fileUploaded
      }
      convertDocToPdf({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          console.log('Response from doc', response)
          setBackdropState(false)
          setPreviewDocumentPdfModalState({ open: true, fileUrl: response?.fileUri })
          setOpenUploadModal(false)
        })
        .catch((exception) => {
          setBackdropState(false)
        })
    } else {
      toast.error('Please check file type ')
    }
  }

  /**
     * Check if the user profile and all other restrictions checks are completed, then intitalize the document
     * @param {object} template : Selected template
     */
  // const handleUploadFormSubmit = (template) => {
  //   console.log(template, 'templatetemplate')
  //   const filePreviewUrl = URL.createObjectURL(fileUploaded)
  //   setobjectUrl(`https://docs.google.com/gview?url=${encodeURIComponent(filePreviewUrl)}`)
  //   console.log('filepreview', filePreviewUrl)
  // }

  /**
     * Check if the user profile and all other restrictions checks are completed, then allow to upload bulk document xlsx
     * @param {string} fileName: fileName
     * @param {array} acceptedFiles: files uploaded
     */
  const handleFile = async (fileName, acceptedFiles) => {
    console.log('in file handling accepted file--->', acceptedFiles[0])
    if (isAllowedDocumentError) {
      return toast.error(isAllowedDocumentError)
    } else {
      const file = acceptedFiles[0]
      const fileType = file?.type // xlsx
      const acceptedFiles1 = BULKFILESACCEPTED
      if (!acceptedFiles1.includes(fileType)) {
        return toast.error('file not accepted')
      } else {
        setMyFiles((prev) => ({
          ...prev,
          bulk: acceptedFiles[0]
        }))

        // const reader = new FileReader()
        // reader.onload = async (event) => {
        //   const data = new Uint8Array(event.target.result)
        //   const workbook = XLSX.read(data, { type: 'array' })
        //   const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        //   const convertedData = await readAsJson(worksheet)
        //   console.log('convertedData', convertedData)
        //   // handleFileCompare(convertedData[0], SAMPLEDOWNLOADFILE[0], acceptedFiles)
        //   // getTotal(convertedData)
        // }
        // reader.readAsArrayBuffer(file)
        console.log('SAMPLEDOWNLOADFILE', SAMPLEDOWNLOADFILE[0], myFiles)
      }
    }
  }

  const handleSubmitBulkUploadDocument = () => {
    setBackdropState(true)
    const dataToSend = {
      bulk: myFiles?.bulk,
      templ: fileUploaded,
      pinCode: intializeForm?.pinCode,
      city: intializeForm?.city,
      sroId: intializeForm?.sroBranch,
      docType: intializeForm?.deliveryType,
      category: intializeForm?.category,
      template: intializeForm?.template
    }
    const params = {
      state: intializeForm?.state
    }

    bulkUploadDocument({ payload: dataToSend, params })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        navigate('/myDocument/bulkTransaction', {
          state: {
            HeaderTitle: 'Bulk Transaction'
          }
        })
        console.log(response, 'initialize')
        console.log(response, 'response')
      })
      .catch((exception) => {
        setBackdropState(false)
        console.log('EXCEPTION:DOCUMENT INITIALIZED', exception)
      })
  }

  const handleBulkTemplateSubmit = () => {
    // setOpenUploadModal(false)
    setPreviewDocumentPdfModalState({ open: false })
    console.log('in bulk upload')
  }

  const removeFile = (fileName) => {
    console.log(fileName)
    const newState = { ...myFiles }
    delete newState[`${fileName}`]
    setMyFiles(newState)

    const formState = { ...intializeForm }
    delete formState[`${fileName}`]
    setIntializeForm(formState)
  }

  const handleInitializeFormPinCodeChange = (event) => {
    console.log(event.target.name)
    if (event?.target?.value === '' || PATTERN?.NUMBERONLY.test(event?.target?.value)) {
      setIntializeForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
        pinCodeStatus: false
      }))
    }
  }
  const handleInitializeFormCopiesChange = (event) => {
    console.log(event.target.name)
    if (event?.target?.value === '' || PATTERN?.NUMBERONLY.test(event?.target?.value)) {
      setIntializeForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value
      }))
    }
  }

  // Check pincode is servicable or not
  const handleCheckPinCodeService = () => {
    const dataToSend = {
      pinCode: intializeForm?.pinCode
    }
    setBackdropState(true)
    checkPinCode(dataToSend)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        if (response?.data) {
          setPinCodeInformation({
            error: 'noerror'
          })

          // set pinCodeStatus to true
          setIntializeForm((prev) => ({
            ...prev,
            pinCodeStatus: true
          }))
        } else {
          setPinCodeInformation({
            error: 'error'
          })
        }
      })
      .catch((exception) => {
        setBackdropState(false)
        setPinCodeInformation({
          error: 'error'
        })
      })
  }

  /**
     * Handle save article template in save in case of custom upload
     * @param {object} template: Selected template from table
     */
  const handleSaveArticleTemplate = (template) => {
    console.log('im here in save ', template)
    setIntializeForm((prev) => ({
      ...prev,
      template: template?.id,
      category: template?.categoryId
    }))
  }

  const handlePartyCountChange = (event) => {
    console.log('PARTY COUNT===>', event?.target?.value)
    // set party in the initialize form
    setRadioButtonPartyCount(event.target.value)
  }

  return (
        <Box p={2} mt={2}>
            <Grid container mb={5} spacing={2}>
                {/* <Button onClick={handleUploadFormSubmit}>pdf</Button> */}
                {/* <CommonPreviewIframe objectUrl={objectUrl} /> */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomCard headerTitle={'Select Information'} height="calc(100% - 20px)">
                        <Grid container mb={1}>
                            <Grid item lg={4} sm={12} xs={12}>
                                <Grid item lg={10} md={12} sm={12} xs={12}>
                                    <Typography variant="darkValue">State</Typography>
                                    <CustomTooltip
                                        message={['Choose the State where Stamp Duty is payable']}
                                        openTooltip={openStateTooltip}
                                        setOpenTooltip={setStateTooltip}
                                        handleTooltipOpen={handleStateTooltipOpen}
                                        handleTooltipClose={handleStateTooltipClose}
                                    />
                                </Grid>
                                <Grid item lg={9} sm={12} xs={12}>
                                    <Select
                                        value={intializeForm?.state || ''}
                                        name="state"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ fontSize: '16px' }}
                                        MenuProps={MenuProps}
                                        fullWidth
                                        onChange={handleStateChange}>
                                        <MenuItem value="" disabled>
                                            <Typography>Select state</Typography>
                                        </MenuItem>
                                        {stateArray.length > 0 &&
                                            stateArray.map((item) => (
                                                <MenuItem
                                                    sx={{ fontSize: '16px' }}
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item lg={4} sm={12} xs={12}>
                                <Grid
                                    item
                                    lg={3}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                      display: 'flex ',
                                      flexDirection: 'row',
                                      alignItems: 'center'
                                    }}>
                                    <Typography variant="darkValue">City/Location</Typography>
                                    <CustomTooltip
                                        message={[
                                          'Pick the city within the State which is related to the execution of document'
                                        ]}
                                        openTooltip={openCityTooltip}
                                        setOpenTooltip={setCityTooltip}
                                        handleTooltipOpen={handleCityTooltipOpen}
                                        handleTooltipClose={handleCityTooltipClose}
                                    />
                                </Grid>
                                <Grid item lg={9} sm={12} xs={12}>
                                    <Select
                                        value={intializeForm?.city || ''}
                                        name="city"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ fontSize: '16px' }}
                                        MenuProps={MenuProps}
                                        fullWidth
                                        onChange={(event) => {
                                          setIntializeForm((prev) => ({
                                            ...prev,
                                            [event.target.name]: event.target.value,
                                            sroBranch: ''
                                          }))
                                        }}>
                                        <MenuItem value="" disabled>
                                            <Typography>Select City</Typography>
                                        </MenuItem>
                                        {cityArray &&
                                            cityArray.map((item) => (
                                                <MenuItem
                                                    sx={{ fontSize: '16px' }}
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item lg={4} sm={12} xs={12}>
                                <Grid
                                    item
                                    lg={9}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                      display: 'flex ',
                                      flexDirection: 'row',
                                      alignItems: 'center'
                                    }}>
                                    <Typography variant="darkValue">Nearest Location</Typography>
                                    <CustomTooltip
                                        message={[
                                          'Choose the nearest location within the city concerning the document'
                                        ]}
                                        openTooltip={openNLocationTooltip}
                                        setOpenTooltip={setNLocationTooltip}
                                        handleTooltipOpen={handleNLocationTooltipOpen}
                                        handleTooltipClose={handleNLocationTooltipClose}
                                    />
                                </Grid>
                                <Grid item lg={9} sm={12} xs={12}>
                                    <Select
                                        disabled={!intializeForm?.city}
                                        value={intializeForm?.sroBranch || ''}
                                        name="sroBranch"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ fontSize: '16px' }}
                                        MenuProps={MenuProps}
                                        fullWidth
                                        onChange={(event) => {
                                          setIntializeForm((prev) => ({
                                            ...prev,
                                            [event.target.name]: event.target.value
                                          }))
                                        }}>
                                        <MenuItem value="" disabled>
                                            <Typography>Select Locality</Typography>
                                        </MenuItem>
                                        {sroBranchList.length > 0 &&
                                            sroBranchList.map((item) => (
                                                <MenuItem
                                                    sx={{ fontSize: '16px' }}
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>

            <Grid container mb={5} spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomCard
                        headerTitle="E-Stamp Mode"
                        height="calc(100% - 20px)"
                        message={[
                          'Wherever permitted, e-Stamp will be available in Digital mode, else Physical e-Stamp will be dispatched.'
                        ]}
                        openTooltip={openEStampTooltip}
                        isToolTip={true}
                        setOpenTooltip={setEStampTooltip}
                        handleTooltipOpen={handlEStampTooltipOpen}
                        handleTooltipClose={handleEStampTooltipClose}>
                        <Grid item lg={12} md={12} pl={4} pt={2} pb={3}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="deliveryType"
                                    value={intializeForm?.deliveryType}
                                    onChange={handleInitializeFormChange}>
                                    {deliveryTypeList.length > 0 &&
                                        deliveryTypeList.map((item, user) => (
                                            <FormControlLabel
                                                key={user}
                                                value={item?.value}
                                                control={
                                                    <Radio
                                                        sx={{
                                                          color: palette.text.main,
                                                          '&.Mui-checked': {
                                                            color: palette.success.dark
                                                          }
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        variant="l2} ml={2}abelSmall"
                                                        sx={{
                                                          color: palette.text.dark,
                                                          fontSize: '14px',
                                                          margin: '10px'
                                                        }}>
                                                        {item?.label}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {intializeForm?.deliveryType === DOCTYPE?.PHYSICAL?.ID.toString() && (
                            <Grid container spacing={2} pl={4}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="darkValue">
                                        Enter the Pin Code to check availability of delivery
                                        services in your area. Please note courier charges will be
                                        applicable
                                    </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={12}>
                                    <Typography variant="darkValue">Pin Code</Typography>
                                </Grid>
                                <Grid item lg={6} md={4} sm={4} xs={12}>
                                    <TextField
                                        value={intializeForm?.pinCode}
                                        type="text"
                                        inputProps={{
                                          type: 'text',
                                          // inputMode: 'numeric',
                                          pattern: '[0-9]*',
                                          maxlength: 6,
                                          minlength: 6
                                        }}
                                        required
                                        id="pinCode"
                                        name="pinCode"
                                        fullWidth
                                        helperText={
                                            pinCodeInformation?.error === 'error'
                                              ? ALERT_MESSAGE.PINCODENOTSERVICEABLENEW
                                              : pinCodeInformation?.error === 'noerror'
                                                ? ALERT_MESSAGE.PINCODESERVICEABLENEW
                                                : ''
                                        }
                                        FormHelperTextProps={{
                                          style: {
                                            color:
                                                    pinCodeInformation?.error === 'error'
                                                      ? 'red'
                                                      : 'green'
                                          }
                                        }}
                                        onChange={handleInitializeFormPinCodeChange}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {!intializeForm?.pinCodeStatus && (
                                        <Button
                                            variant="primary"
                                            disabled={intializeForm?.pinCode?.length < 6}
                                            onClick={handleCheckPinCodeService}>
                                            Check
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {pinCodeInformation?.error === 'noerror' && (
                            <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  marginTop: '10px'
                                }}>
                                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="darkValue" sx={{ fontSize: '15px' }}>
                                        {documentCopyModalState.label}
                                        <CustomTooltip
                                            message={[
                                              'All physically executed documents would need wet signatures and can be executed in one or more counterparts on chargeable basis.'
                                            ]}
                                            openTooltip={openCopiesTooltip}
                                            setOpenTooltip={setCopiesTooltip}
                                            handleTooltipOpen={handleCopiesTooltipOpen}
                                            handleTooltipClose={handleCopiesTooltipClose}
                                        />
                                    </Typography>
                                </Grid>

                                <Grid
                                    display="flex"
                                    justifyContent="space-between"
                                    gap={2}
                                    alignItems="center">
                                    <TextField
                                        value={intializeForm?.copies}
                                        type="text"
                                        inputProps={{
                                          type: 'text',
                                          // inputMode: 'numeric',
                                          pattern: '[0-9]*',
                                          maxlength: 2,
                                          minlength: 1
                                        }}
                                        sx={{ width: '180px' }}
                                        required
                                        id="copies"
                                        name="copies"
                                        onChange={handleInitializeFormCopiesChange}
                                        onKeyDown={handlePreventSpace}
                                    />
                                </Grid>
                            </div>
                        )}
                    </CustomCard>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomCard
                        headerTitle="Select Template Type"
                        height="calc(100% - 20px)"
                        openTooltip={openTempTypeTooltip}
                        message={[
                          '1.) Choose "System Generated" to use ready made drafts provided below.',
                          '2.) Choose "Custom Document Upload" if you wish to upload your own draft.'
                        ]}
                        isToolTip={true}
                        setOpenTooltip={setTempTypeTooltip}
                        handleTooltipOpen={handlTempTypeTooltipOpen}
                        handleTooltipClose={handleTempTypeTooltipClose}>
                        <Grid container alignItems={'center'} spacing={2} m={2}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={radioButtonTemplate}
                                    onChange={handleRadioButtonTemplate}>
                                    {optionsArray &&
                                        optionsArray.map((item, user) => (
                                            <FormControlLabel
                                                key={user}
                                                value={item?.value}
                                                control={
                                                    <Radio
                                                        sx={{
                                                          color: palette.text.main,
                                                          '&.Mui-checked': {
                                                            color: palette.success.dark
                                                          }
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Grid>
                                                        <Typography
                                                            variant="labelSmall"
                                                            sx={{
                                                              color: palette.text.dark,
                                                              fontSize: '14px'
                                                            }}>
                                                            {item?.label}
                                                        </Typography>
                                                    </Grid>
                                                }
                                            />
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
            {/* <>
                <Grid container mb={5} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomCard
                            headerTitle="How many copies of document do you want?"
                            height="calc(100% - 20px)"
                            openTooltip={openCopyCountTooltip}
                            message={CREATEDOCUMENTMESSAGE}
                            isToolTip={true}
                            setOpenTooltip={setCopyCountTooltip}
                            handleTooltipOpen={handlCopyCountTooltipOpen}
                            handleTooltipClose={handleCopyCountTooltipClose}>
                            <Grid item lg={12} md={12}>
                                <FormControl>
                                    <Grid item lg={12} md={6} sm={6} xs={12}>
                                        <TextField
                                            value={intializeForm?.copies}
                                            type="text"
                                            inputProps={{
                                              type: 'text',
                                              // inputMode: 'numeric',
                                              pattern: '[0-9]*',
                                              maxlength: 2,
                                              minlength: 1
                                            }}
                                            required
                                            id="copies"
                                            name="copies"
                                            onChange={handleInitializeFormCopiesChange}
                                        />
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </CustomCard>
                    </Grid>
                </Grid>
            </> */}
            <>
                <Grid container mb={5}>
                    <TemplateTable
                        articleTemplateList={
                            articleTemplateResponse?.categories
                              ? articleTemplateResponse?.categories
                              : []
                        }
                        handleFormSubmit={
                            radioButtonTemplate === TEMPLATETYPE?.SYSTEMGENERATED?.value
                              ? handleFormSubmit
                              : handleSaveArticleTemplate
                        }
                        templateTypeSelected={radioButtonTemplate}
                        initializeForm={intializeForm}
                    />
                </Grid>
                {/* </CustomCard> */}
            </>

            {radioButtonTemplate === 'customUpload' && (
                <>
                    {userInfo?.role === USER_ROLE.CORPORATE.toUpperCase() && (
                        <Grid container direction="column" mb={2}>
                            <Typography variant="darkValueLarge" mb={2}>
                                Please select type of transaction
                            </Typography>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={radioButtonUpload}
                                    onChange={handleRadioButtonUpload}>
                                    {optionsArrayBulk &&
                                        optionsArrayBulk.map((item, user) => (
                                            <FormControlLabel
                                                key={user}
                                                value={item?.value}
                                                control={
                                                    <Radio
                                                        sx={{
                                                          color: palette.text.main,
                                                          '&.Mui-checked': {
                                                            color: palette.success.dark
                                                          }
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        variant="labelSmall"
                                                        sx={{
                                                          color: palette.text.dark,
                                                          fontSize: '14px'
                                                        }}>
                                                        {item?.label}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid container display="flex" alignItems={'center'}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CustomCard headerTitle={'Upload Document'}>
                                {/* <Grid item lg={6} sm={12} xs={12} md={12}>
                                <Grid container alignItems={'center'} spacing={2} p={3}>
                                    <Grid item lg={3} sm={3} xs={3} md={3}>
                                        <Typography variant="darkValue">Article type</Typography>
                                    </Grid>
                                    <Grid item lg={9} sm={9} xs={9} md={9}>
                                        <Select
                                            value={intializeForm?.articleType || ''}
                                            name="articleType"
                                            displayEmpty
                                            inputProps={{
                                              'aria-label': 'Without label'
                                            }}
                                            sx={{ fontSize: '16px' }}
                                            MenuProps={MenuProps}
                                            fullWidth
                                            onChange={handleInitializeFormChange}>
                                            <MenuItem value="" disabled>
                                                <Typography>Select Article type</Typography>
                                            </MenuItem>
                                            {articleTypeArray &&
                                                articleTypeArray.map((item) => (
                                                    <MenuItem
                                                        sx={{ fontSize: '16px' }}
                                                        key={item.id}
                                                        value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                                {radioButtonUpload !== 'bulkUpload' ? (
                                    // <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        p={1}
                                        sx={{
                                          border: `0.5px dashed ${palette.text.main}`,
                                          background: palette.secondary.light,
                                          borderRadius: '5px'
                                        }}>
                                        <Button
                                            onClick={handleOpenUploadModal}
                                            sx={{
                                              color: palette.text.dark,
                                              textTransform: 'none'
                                            }}
                                            startIcon={
                                                <img
                                                    src={uploadicon}
                                                    style={{ marginRight: '20px' }}
                                                    alt="Upload Document"
                                                />
                                            }
                                            disabled={!intializeForm?.template}>
                                            Upload custom Document
                                        </Button>
                                    </Grid>
                                ) : (
                                    // </Grid>
                                    <>
                                        <Grid
                                            item
                                            lg={12}
                                            sm={12}
                                            xs={12}
                                            sx={{
                                              border: `0.5px dashed ${palette.text.main}`,
                                              background: palette.secondary.light,
                                              borderRadius: '5px',
                                              height: '50px'
                                            }}
                                            p={1}>
                                            <Button
                                                onClick={handleOpenUploadModal}
                                                sx={{
                                                  color: palette.text.dark,
                                                  textTransform: 'none'
                                                }}
                                                startIcon={
                                                    <img
                                                        src={uploadicon}
                                                        style={{ marginRight: '20px' }}
                                                        alt="Upload Document"
                                                    />
                                                }
                                                disabled={!intializeForm?.template}>
                                                Upload custom Document
                                            </Button>
                                        </Grid>

                                        {fileUploaded && (
                                            <>
                                                {/* <Grid item lg={6}></Grid> */}
                                                <Grid
                                                    item
                                                    lg={6}
                                                    sm={12}
                                                    xs={12}
                                                    p={1}
                                                    sx={{
                                                      display: 'flex',
                                                      justifyContent: 'flex-start',
                                                      marginTop: {
                                                        // lg: '-30px',
                                                        sm: '0px',
                                                        md: '0px',
                                                        xs: '0px'
                                                      }
                                                    }}>
                                                    <ul type="none" className="filesList">
                                                        <li style={{ marginRight: '10px' }}>
                                                            <Grid className="uploadFile">
                                                                <Grid>
                                                                    <Typography
                                                                        sx={{
                                                                          fontSize: '13px',
                                                                          fontWeight: 600,
                                                                          wordBreak: 'break-all'
                                                                        }}>
                                                                        {fileUploaded?.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </CustomCard>
                        </Grid>
                    </Grid>

                    {radioButtonUpload === 'bulkUpload' && (
                        <>
                            <Grid container direction="column">
                                <Typography variant="darkValueLarge" mb={2}>
                                    Use this sample file to fill data and upload
                                </Typography>
                                <Grid>
                                    <Box
                                        sx={{
                                          width: '300px',
                                          border: '1px dashed #bfbfbf',
                                          borderRadius: '5px'
                                        }}
                                        mb={3}
                                        className={!intializeForm?.template ? 'is-disabled' : ''}
                                        // onClick={handleExportSampleExcel}
                                    >
                                        <a
                                            href={sampleFile?.link}
                                            style={{ textDecoration: 'none' }}>
                                            <div className="uploadDocuments">
                                                <img
                                                    src={downloadIcon}
                                                    height="18px"
                                                    style={{ marginRight: '18px' }}
                                                    alt="Download"
                                                />
                                                <Typography
                                                    variant="darkValue"
                                                    sx={{ textDecoration: 'underline' }}>
                                                    Sample.xls
                                                </Typography>
                                            </div>
                                        </a>
                                    </Box>
                                </Grid>
                                <Typography variant="darkValueLarge" mb={2}>
                                    Please upload the bulk data file
                                </Typography>
                                <Box
                                    sx={{ width: '300px' }}
                                    mb={5}
                                    className={
                                        !(intializeForm?.template && fileUploaded)
                                          ? 'is-disabled'
                                          : ''
                                    }>
                                    <Dropzone
                                        text={'Upload bulk file'}
                                        handleFile={handleFile}
                                        fileName={'Uploadedbulkfile'}
                                    />
                                    {myFiles?.bulk && (
                                        <ul type="none" className="filesList">
                                            <Files
                                                fileName={'bulk'}
                                                myFiles={myFiles}
                                                removeFile={removeFile}
                                            />
                                        </ul>
                                    )}
                                </Box>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="primary"
                                    onClick={handleSubmitBulkUploadDocument}
                                    disabled={
                                        !(intializeForm?.template && fileUploaded && myFiles?.bulk)
                                    }>
                                    Proceed
                                </Button>
                            </Grid>
                        </>
                    )}
                </>
            )}

            {/* {radioButtonTemplate === 'systemGenerated' && ( */}

            {/* )} */}

            {openUploadModal && (
                <UploadDocsModal
                    openUploadModal={openUploadModal}
                    handleUploadModalClose={handleUploadModalClose}
                    fileUploaded={fileUploaded}
                    setFileUploaded={setFileUploaded}
                    handleFormSubmit={handleUploadCustomDocumentForm}
                    // handleFile={handleFile}
                />
            )}

            {notesModalState?.open && (
                <CommonMessageModal
                    CommonModalState={notesModalState}
                    handleOkButtonCommonModal={() => {
                      setNotesModalState({
                        open: false,
                        message: ''
                      })
                    }}
                />
            )}

            {previewDocumentPdfModalState?.open && (
                <PreviewDocumentPdfModal
                    previewDocumentPdfModalState={previewDocumentPdfModalState}
                    handlePrevieDocumentPdfModalClose={() => {
                      setPreviewDocumentPdfModalState({ open: false })
                    }}
                    handleFormSubmit={
                        radioButtonUpload === 'bulkUpload'
                          ? handleBulkTemplateSubmit
                          : handleFormSubmit
                    }
                />
            )}

            {openPartyCountModal && (
                <PartyCountModal
                    partyCountModalState={openPartyCountModal}
                    handleAlertModalClose={() => setOpenPartyCountModal(false)}
                    handlePartyCountChange={handlePartyCountChange}
                    partyCountOptions={partyCountOptions}
                    partyCountValue={radioButtonPartyCount}
                />
            )}

            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default CreateDocument
