import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardHeader } from '@mui/material'
import PropTypes from 'prop-types'
import palette from '../../theme/palette'

export default function CustomCard ({ headerTitle, children, action, height }) {
  return (
    <Card sx={{ border: `0.5px solid ${palette.text.light}`, height: { height }, backgroundColor: palette.primary.light, borderRadius: '5px', marginBottom: ' 30px' }}>
      <CardHeader action={action}
      sx={{ borderBottom: `0.5px solid ${palette.text.light}`, backgroundColor: palette.secondary.light }} title={headerTitle} titleTypographyProps={{ fontWeight: '600', fontSize: '18px', color: palette.text.dark }}>
      </CardHeader>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  )
}
CustomCard.propTypes = {
  headerTitle: PropTypes?.string,
  children: PropTypes.node,
  action: PropTypes.object,
  height: PropTypes.string
}
