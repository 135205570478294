/* eslint-disable */
import moment from 'moment';
import { validator } from './validator';
import { TEMPLATEFIELDTYPES } from '../constants/enum';

export function validateDocumentSection(sections, form) {
    const validationMesaages = [];
    console.log(sections, form);
    sections.map((field) => {
        field?.subFields?.map((subFields) => {
            if (
                subFields?.props.prefilled &&
                !form[`${subFields?.id}`] &&
                form[`${subFields?.id}`] !== ''
            ) {
                return true;
            }

            // if (!form[`${subFields?.id}`] || form[`${subFields?.id}`] === '') {
            const message = fieldCheck(field.titleText, subFields, form);
            if (message && message !== 'noerror') {
                console.log(message);
                validationMesaages.push({
                    fieldId: subFields.id,
                    errorMessage: message
                });
            }
            // }
            return true;
        });
        return true;
    });
    return validationMesaages;
}

function fieldCheck(fieldName, subFields, form) {
    console.log(fieldName, subFields, form, 'hkhkhk');
    if (subFields?.props?.mandatory === 'true' && !form[`${subFields.id}`]) {
        console.log(form[`${subFields.id}`]);
        return `${fieldName} is required`;
    } else if (
        Number(subFields?.props?.minlength) &&
        form[`${subFields.id}`].length < Number(subFields?.props?.minlength)
    ) {
        return `Minimum length ${subFields?.props?.minlength} for  ${fieldName} is required`;
    } else if (
        Number(subFields?.props?.min_value) &&
        form[`${subFields.id}`] < Number(subFields?.props?.min_value)
    ) {
        return `Minimum Value Should be ${subFields?.props?.min_value} for ${fieldName}.`;
    } else if (
        Number(subFields?.props.maxlength) &&
        form[`${subFields.id}`] > subFields?.props?.maxlength
    ) {
        return `Maximum length ${subFields?.props?.maxlength} for  ${fieldName} is required`;
    } else if (subFields?.props.min_date) {
        const formDate = moment(form[`${subFields.id}`], 'YYYY-MM-DD');
        const minDate = moment(subFields?.props?.min_date, 'YYYY-MM-DD');

        if (formDate.isBefore(minDate)) {
            return 'Past date is not allowed.';
        }
    } else if (subFields?.props.max_date) {
        const formDate = moment(form[`${subFields.id}`], 'YYYY-MM-DD');
        const maxDate = moment(subFields?.props?.max_date, 'YYYY-MM-DD');
        if (formDate.isAfter(maxDate)) {
            return `Please select valid ${fieldName}`;
        }
    }
    const errorMessage = validator(subFields?.type, form[`${subFields.id}`]);
    return errorMessage;
}

/**
 * Function to return Reviewer's to be added in the review consent form according to the fields filled in the template
 *@param {object}totalTemplateReviewerObject: Total Template Fields Object
 *@param {array} partyTypeArray: Party Types for the respective document
 *@return {array} reviewers list
 */
export function provideReviewersList(
    totalTemplateReviewerObject,
    partyTypeArray,
    documentReviewers,
    sectionIndex
) {
    console.log('SECTION OBJECT====>', totalTemplateReviewerObject, sectionIndex);
    // Finding the object where updation is done
    const sectionObject = Object.keys(totalTemplateReviewerObject).filter(
        (item) => totalTemplateReviewerObject[item]?.index === sectionIndex
    );
    let updatedReviewersList = [...documentReviewers];
    if (sectionObject !== undefined || sectionObject !== '') {
        console.log('SECTION OBJECT====>', sectionObject);

        const reviewerToUpdate = totalTemplateReviewerObject[sectionObject];
        const partyTypeData =
            partyTypeArray.find(
                (item) =>
                    item.name.toLowerCase() ===
                    TEMPLATEFIELDTYPES[sectionObject].NAME.replace(/_/g, ' ').toLowerCase()
            ) || {};

        // Find index of the item in documentReviewer with matching id
        const existingReviewerIndex = updatedReviewersList.findIndex(
            (reviewer) => reviewer.id === reviewerToUpdate.id
        );

        if (existingReviewerIndex >= 0) {
            // Update existing item
            updatedReviewersList[existingReviewerIndex] = {
                ...updatedReviewersList[existingReviewerIndex],
                name: reviewerToUpdate.NAME || updatedReviewersList[existingReviewerIndex].name,
                address:
                    reviewerToUpdate.ADDRESS || updatedReviewersList[existingReviewerIndex].address,
                priority:
                    reviewerToUpdate.priority ||
                    updatedReviewersList[existingReviewerIndex].priority,
                consent:
                    reviewerToUpdate.consent || updatedReviewersList[existingReviewerIndex].consent,
                eSign: reviewerToUpdate.eSign || updatedReviewersList[existingReviewerIndex].eSign,
                email: reviewerToUpdate.email || updatedReviewersList[existingReviewerIndex].email,
                phone: reviewerToUpdate.phone || updatedReviewersList[existingReviewerIndex].phone,
                signMode:
                    reviewerToUpdate.signMode ||
                    updatedReviewersList[existingReviewerIndex].signMode,
                isError:
                    reviewerToUpdate.isError !== undefined
                        ? reviewerToUpdate.isError
                        : updatedReviewersList[existingReviewerIndex].isError
            };
        } else {
            // Add new item to updatedReviewersList if id is not found
            updatedReviewersList.push({
                partyType: partyTypeData, // Assign partyType from the key (e.g., OWNER or TENANT)
                name: reviewerToUpdate.NAME || '',
                address: reviewerToUpdate.ADDRESS || '',
                priority: reviewerToUpdate.priority || '',
                consent: reviewerToUpdate.consent || false,
                eSign: reviewerToUpdate.eSign || false,
                email: reviewerToUpdate.email || '',
                phone: reviewerToUpdate.phone || '',
                signMode: reviewerToUpdate.signMode || 2,
                isError: reviewerToUpdate.isError !== undefined ? reviewerToUpdate.isError : true,
                id: reviewerToUpdate.id // Ensure the unique ID is assigned
            });
        }
    }
    // Return the updated documentReviewer array
    return updatedReviewersList;
}

export function provideReviewerEntriesNoIndex(
    totalTemplateReviewerObject,
    partyTypeArray,
    documentReviewers
) {
    let updatedReviewersList = [...documentReviewers];

    // Iterate over the totalTemplateReviewerObject
    Object.keys(totalTemplateReviewerObject).forEach((templateItem) => {
        const reviewerToUpdate = totalTemplateReviewerObject[templateItem];
        const partyTypeData =
            partyTypeArray.find(
                (item) =>
                    item.name.toLowerCase() ===
                    TEMPLATEFIELDTYPES[templateItem].NAME.replace(/_/g, ' ').toLowerCase()
            ) || {};
        // Find index of the item in documentReviewer with matching id
        const existingReviewerIndex = updatedReviewersList.findIndex(
            (reviewer) => reviewer.id === reviewerToUpdate.id
        );

        if (existingReviewerIndex >= 0) {
            // Update existing item
            updatedReviewersList[existingReviewerIndex] = {
                ...updatedReviewersList[existingReviewerIndex],
                name: reviewerToUpdate.NAME || updatedReviewersList[existingReviewerIndex].name,
                address:
                    reviewerToUpdate.ADDRESS || updatedReviewersList[existingReviewerIndex].address,
                priority:
                    reviewerToUpdate.priority ||
                    updatedReviewersList[existingReviewerIndex].priority,
                consent:
                    reviewerToUpdate.consent || updatedReviewersList[existingReviewerIndex].consent,
                eSign: reviewerToUpdate.eSign || updatedReviewersList[existingReviewerIndex].eSign,
                email: reviewerToUpdate.email || updatedReviewersList[existingReviewerIndex].email,
                phone: reviewerToUpdate.phone || updatedReviewersList[existingReviewerIndex].phone,
                signMode:
                    reviewerToUpdate.signMode ||
                    updatedReviewersList[existingReviewerIndex].signMode,
                isError:
                    reviewerToUpdate.isError !== undefined
                        ? reviewerToUpdate.isError
                        : updatedReviewersList[existingReviewerIndex].isError
            };
        } else {
            // Add new item to updatedReviewersList if id is not found
            updatedReviewersList.push({
                partyType: partyTypeData, // Assign partyType from the key (e.g., OWNER or TENANT)
                name: reviewerToUpdate.NAME || '',
                address: reviewerToUpdate.ADDRESS || '',
                priority: reviewerToUpdate.priority || '',
                consent: reviewerToUpdate.consent || false,
                eSign: reviewerToUpdate.eSign || false,
                email: reviewerToUpdate.email || '',
                phone: reviewerToUpdate.phone || '',
                signMode: reviewerToUpdate.signMode || 2,
                isError: reviewerToUpdate.isError !== undefined ? reviewerToUpdate.isError : true,
                id: reviewerToUpdate.id
            });
        }
    });

    return updatedReviewersList;
}
